<template>
  <fieldset aria-label="Choose a viewing method">
    <RadioGroup v-model="isTableView" class="flex ring-1 ring-gray-300 dark:ring-neutral-800 rounded-md overflow-hidden">
      <RadioGroupOption :value="false" v-slot="{ active, checked }">
        <div
          :class="[checked ? 'bg-operacolor text-white' : 'bg-white dark:bg-neutral-950 dark:text-neutral-100 text-gray-900 hover:bg-gray-50', 'cursor-pointer focus:outline-none flex items-center justify-center px-2 py-1.5 text-sm font-semibold uppercase sm:flex-1']">
          <Squares2X2Icon class="size-5" />
        </div>
      </RadioGroupOption>
      <RadioGroupOption :value="true" v-slot="{ active, checked }">
        <div
          :class="[checked ? 'bg-operacolor text-white' : 'bg-white dark:bg-neutral-950 dark:text-neutral-100 text-gray-900 hover:bg-gray-50', 'cursor-pointer focus:outline-none flex items-center justify-center px-2 py-1.5 text-sm font-semibold uppercase sm:flex-1']">
          <Bars3Icon class="size-5" />
        </div>
      </RadioGroupOption>
    </RadioGroup>
  </fieldset>
</template>

<script setup>
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { Squares2X2Icon, Bars3Icon } from '@heroicons/vue/24/outline';

const isTableView = defineModel() // true for "card" view, false for "table" view
</script>
