<template>
  <!-- Table Component -->
  <div class="flow-root">
    <div>
      <div class="mb-2 text-right flex justify-end gap-6 items-center">

        <div v-if="props.allow_export && selectedRows.length > 0" class="text-sm text-operacolor flex items-center gap-2 -mr-3">
          <ButtonSecondary text="" size="sm" :icon="XMarkIcon" @click.stop="deselectAll" />
          {{ selectedRows.length }} rows selected
        </div>

        <div v-if="props.allow_export && selectedRows.length > 0">
          <ButtonPrimary text="Export to CSV" size="lg" @click.stop="handleExport" :loading="loadingExport" :icon="ArrowDownTrayIcon" :iconFirst="true" />
        </div>

        <ColumnSelector v-model="columns" />
      </div>

      <div class="w-full inline-block align-middle">
        <div class="w-full rounded-lg shadow dark:shadow-none ring-1 ring-black ring-opacity-5">

          <!-- Header -->
          <div class="w-full bg-gray-100 dark:bg-neutral-800 rounded-t-lg bg-opacity-75 backdrop-blur backdrop-filter border-b border-gray-300 dark:border-neutral-700">
            <div class="flex">
              <Checkbox v-model="selectAll" class="pl-4 sm:pl-6 py-3.5" />
              <div v-for="column in visibleColumns" :key="column.key"
                class="px-3 py-3.5 text-sm font-semibold text-gray-900  dark:text-neutral-300 last:pr-5 last:text-right whitespace-nowrap flex-1 flex last:justify-end">
                <span v-if="column?.label" class="flex items-center gap-1">
                  {{ column.label }}
                  <ArrowsUpDownIcon @click="sortByColumn(column)" class="w-4 h-4 mt-0.5 hover:text-operacolor cursor-pointer"
                    :class="[sorter.key == column.key ? 'text-operacolor' : 'text-gray-400 dark:text-neutral-500']" />
                </span>
              </div>
            </div>
          </div>

          <!-- Body -->
          <div class="bg-white dark:bg-neutral-900 max-h-[60vh] overflow-y-scroll custom-srollbar">
            <div v-for="row in visibleRows" :key="row" @click="handleClick(row)"
              :class="[props.isClickable ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-neutral-950' : '', row.selected ? 'bg-sky-50 hover:bg-sky-100 dark:bg-blue-950 dark:hover:bg-blue-900' : '', 'flex w-full border-b border-gray-200 dark:border-neutral-700 last:border-b-0']">

              <Checkbox v-model="row.selected" class="pl-4 sm:pl-6 py-4" @click.stop />

              <div v-for="column in visibleColumns" :key="column.key" class="py-4 px-3 text-sm text-gray-500 dark:text-neutral-300 truncate whitespace-nowrap last:pr-5 last:text-right flex-1">

                <!-- Badges -->
                <div v-if="column.type == 'badge'">
                  <span v-if="getNestedValue(row, column.key)" :class="[getBadgeClass(getNestedValue(row, column.key), true), 'capitalize']">
                    {{ getUserFriendlyString(getNestedValue(row, column.key), userStore?.user?.profiles_protected?.role) }}
                  </span>
                  <span v-else>-</span>
                </div>

                <!-- Badge Array -->
                <div v-else-if="column.type == 'badge-array'">
                  <div v-if="getNestedValue(row, column.key)">
                    <span v-for="badge in getNestedValue(row, column.key)" :class="[getBadgeClass(badge, true), 'capitalize mr-2']">
                      {{ getUserFriendlyString(badge, userStore?.user?.profiles_protected?.role) }}
                    </span>
                  </div>
                  <span v-else>-</span>
                </div>

                <!-- Date -->
                <span v-else-if="column.type == 'date'">{{ formatDate(getNestedValue(row, column.key)) ?? '-' }}</span>

                <!-- Two Strings -->
                <span v-else-if="column.type == 'two-strings'">{{ `${getNestedValue(row, column.key) ?? '-'} ${getNestedValue(row, column.key2) ?? ''}` }}</span>

                <!-- Bold -->
                <Tooltip v-else-if="column.type == 'bold'" :content="getNestedValue(row, column.key) ?? '-'">
                  <div class="truncate">
                    <span class="font-medium text-gray-900 dark:text-neutral-100">{{ getNestedValue(row, column.key) ?? '-' }}</span>
                  </div>
                </Tooltip>

                <!-- Small -->
                <!-- <span v-else-if="column.type == 'small'" class="text-xs">{{ getNestedValue(row, column.key) ?? '-' }}</span> -->

                <!-- Copiable -->
                <span v-else-if="column.type == 'copy'" class="flex items-center gap-1">
                  <div>{{ getNestedValue(row, column.key) ?? '-' }}</div>
                  <Copy v-if="getNestedValue(row, column.key)" :text="getNestedValue(row, column.key)" @click.stop />
                </span>

                <!-- Capitaize -->
                <span v-else-if="column.type == 'capitalize'" class="capitalize">{{ (getNestedValue(row, column.key)) ?? '-' }}</span>

                <!-- Money -->
                <span v-else-if="column.type == 'money'">{{ formatMoney(getNestedValue(row, column.key)) ?? '-' }}</span>

                <!-- Total -->
                <span v-else-if="column.type == 'multiply'">
                  {{ formatMoney(getNestedValue(row, column.key) * getNestedValue(row, column.key2)) ?? '-' }}
                </span>

                <!-- Partner Logo -->
                <span v-else-if="column.type == 'partner-logo'">
                  <PartnerLogo :partner="getNestedValue(row, column.key)" size="small" />
                </span>

                <!-- Button Primary -->
                <span v-else-if="column.type == 'button-primary'">
                  <div @click.stop="handleButtonPrimaryClicked(row)" class="button_primary">{{ column.key }}</div>
                </span>

                <!-- Button Secondary -->
                <span v-else-if="column.type == 'button-secondary'">
                  <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary">{{ column.key }}</div>
                </span>

                <!-- Esign Status -->
                <!-- <span v-else-if="column.type == 'custom-esign'" class="flex justify-end">
                  <div v-if="getNestedValue(row, column.key) == 'SIGNED'" class="flex w-fit flex-shrink-0 gap-1 items-center">
                    <CheckCircleIcon class="w-6 h-6 text-green-600" />
                    <span class="secondary_header ml-1">Signing Complete</span>
                  </div>
                  <div v-else>
                    <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary mr-5">
                      Resend Email
                      <LoadGifButton v-if="row.loading" class="-ml-px -mr-px" />
                      <EnvelopeIcon v-else class="w-4 h-4" />
                    </div>
                    <div @click.stop="handleButtonPrimaryClicked(row)" class="button_primary">
                      Sign
                      <PencilIcon class="w-4 h-4" />
                    </div>
                  </div>
                </span> -->

                <!-- Signed -->
                <!-- <span v-else-if="column.type == 'custom-signed'">
                  <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary">{{ column.key }}</div>
                </span> -->

                <!-- Default Fill -->
                <span v-else-if="column.type == 'default-fill'">{{ getNestedValue(row, column.key) ?? '-' }}</span>

                <!-- Default -->
                <span v-else>{{ getNestedValue(row, column.key) }}</span>

              </div>
            </div>
          </div>

          <div class="items-center justify-center w-full bg-white dark:bg-neutral-900" v-if="props.rows <= 0">
            <div class="flex items-center justify-center w-full py-8 font-semibold dark:bg-neutral-900 dark:text-neutral-300 text-gray-600">{{ props.empty_message }}</div>
          </div>

          <!-- Pagination -->
          <div class="flex items-center justify-between border-t border-gray-200 dark:border-neutral-700 px-4 py-3 sm:px-6 dark:bg-neutral-800 bg-gray-100 rounded-b-lg">
            <!-- <div class="flex flex-1 justify-between sm:hidden">
              <div class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</div>
              <div class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 dark:text-neutral-300 hover:bg-gray-50">Next
              </div>
            </div> -->
            <div class="flex flex-1 items-center justify-between">
              <div>
                <div class="text-sm text-gray-700 dark:text-neutral-300">
                  Showing
                  {{ ' ' }}
                  <span class="font-medium">{{ startResultIndex }}</span>
                  {{ ' ' }}
                  to
                  {{ ' ' }}
                  <span class="font-medium">{{ endResultIndex }}</span>
                  {{ ' ' }}
                  of
                  {{ ' ' }}
                  <span v-if="props.loading_all" class="h-[13px] w-6 inline-block">
                    <SpinnerButton class="h-2 w-2" aria-hidden="true" />
                  </span>
                  <span v-else class="font-medium">{{ sortedRows.length }}</span>
                  {{ ' ' }}
                  results
                </div>
              </div>
              <div>
                <nav class="isolate inline-flex -space-x-px rounded-md shadow dark:shadow-none bg-white dark:bg-neutral-950 cursor-pointer" aria-label="Pagination">
                  <div @click="firstPage"
                    :class="[currentPage == 1 ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900' : 'text-gray-600 dark:text-neutral-300 dark:hover:bg-neutral-800 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-l-md ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0']">
                    <span class="sr-only">Previous</span>
                    <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div @click="prevPage"
                    :class="[(currentPage == 1) ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900' : 'text-gray-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800', 'relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0']">
                    <span class="sr-only">Previous</span>
                    <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                  </div>

                  <span v-if="currentPage > 2 && pages > 4"
                    class="relative inline-flex items-center px-3.5 py-2 text-sm font-semibold text-gray-700 dark:text-neutral-300 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:outline-offset-0 cursor-text">
                    ...
                  </span>
                  <div v-if="(pages - currentPage) < 1 && currentPage > 3 && pages > 3" @click="setSpecificPage(currentPage - 3)"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage - 3 }}
                  </div>
                  <div v-if="(pages - currentPage) < 2 && currentPage > 2 && pages > 2" @click="setSpecificPage(currentPage - 2)"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage - 2 }}
                  </div>
                  <div v-if="currentPage > 1" @click="setSpecificPage(currentPage - 1)"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage - 1 }}
                  </div>
                  <div
                    class="bg-operacolor z-10 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-operacolor relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage }}
                  </div>
                  <div v-if="pages > currentPage" @click="setSpecificPage(currentPage + 1)"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage + 1 }}
                  </div>
                  <div v-if="pages > currentPage && currentPage < 3 && pages >= currentPage + 2" @click="setSpecificPage(currentPage + 2)"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage + 2 }}
                  </div>
                  <div v-if="pages > currentPage && currentPage < 2 && pages >= currentPage + 3" @click="setSpecificPage(currentPage + 3)"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20">
                    {{ currentPage + 3 }}
                  </div>
                  <span v-if="pages > currentPage + 1 && pages > 4"
                    class="relative inline-flex items-center px-3.5 py-2 text-sm font-semibold text-gray-700 dark:text-neutral-300 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:outline-offset-0 cursor-default">
                    <Tooltip :content="`${pages} pages`">
                      <div>...</div>
                    </Tooltip>
                  </span>




                  <div @click="nextPage"
                    :class="[(pages == currentPage) ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900' : 'text-gray-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800', 'relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0']">
                    <span class="sr-only">Next</span>
                    <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div @click="lastPage"
                    :class="[(pages == currentPage) ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900' : 'text-gray-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800', 'relative inline-flex items-center px-2 py-2 ring-1 ring-inset rounded-r-md ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0']">
                    <span class="sr-only">Next</span>
                    <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                </nav>
              </div>
            </div>
          </div>

        </div>



      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, watch } from 'vue';
import { formatMoney, formatDate, getBadgeClass, getUserFriendlyString } from '@/helper/helper';

// Stores
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

// Components
import Checkbox from '@/components/applicationui/Checkbox.vue'
import PartnerLogo from '@/components/widgets/PartnerLogo.vue'
import Tooltip from '@/components/applicationui/Tooltip.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import SpinnerButton from '@/components/loading/SpinnerButton.vue';
import ColumnSelector from '@/components/ui/ColumnSelector.vue';
import Copy from '@/components/applicationui/Copy.vue';

// Libraries
import { ArrowsUpDownIcon, ArrowDownTrayIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon, XMarkIcon } from '@heroicons/vue/20/solid';

// import { XMarkIcon } from '@heroicons/vue/24/outline';
// import { PencilIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
// import { CheckCircleIcon } from '@heroicons/vue/20/solid';
// Stores

const columns = defineModel()
const visibleColumns = computed(() => columns.value.filter(column => column.visible))

// Props
const props = defineProps({
  //This is an array of objects that contains the data to be displayed in the table
  rows: {
    type: Array,
    required: true,
    default: null
  },
  // This is an array of objects that contains the columns there should be two values to each object, key and label
  // columns: {
  //   type: Array,
  //   required: true,
  //   default: null
  // },
  // This is a boolean that determines if the row is clickable
  isClickable: {
    type: Boolean,
    required: false,
    default: false
  },
  allow_export: {
    type: Boolean,
    required: false,
    default: true
  },
  // This is a boolean that determines if the table is loading (the first 50 results)
  loading: {
    type: Boolean,
    required: false,
    default: false
  },
  // This is a boolean that determines if the table has loaded all the data
  loading_all: {
    type: Boolean,
    required: false,
    default: false
  },
  // This is a string that determines the title of the exported csv file
  export_title: {
    type: String,
    required: false,
    default: 'opera'
  },
  empty_message: {
    type: String,
    required: false,
    default: 'No results found'
  }
});
// Emits
const emit = defineEmits(['click-primary', 'click-secondary']);

// Globals

// Sorting
const sorter = ref({ key: null, order: 'asc' });
// Sort the rows passed in props
const sortedRows = computed(() => {
  if (!sorter.value.key) return props.rows;

  const sortedArray = [...props.rows].sort((a, b) => {
    const valueA = getNestedValue(a, sorter.value.key);
    const valueB = getNestedValue(b, sorter.value.key);

    if (valueA == null) return sorter.value.order === 'asc' ? -1 : 1;
    if (valueB == null) return sorter.value.order === 'asc' ? 1 : -1;
    if (valueA < valueB) return sorter.value.order === 'asc' ? -1 : 1;
    if (valueA > valueB) return sorter.value.order === 'asc' ? 1 : -1;
    return 0;
  });

  return sortedArray;
});
// Sort the rows when a column is clicked
const sortByColumn = (column) => {
  if (sorter.value.key === column.key) {
    sorter.value.order = sorter.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    sorter.value.key = column.key;
    sorter.value.order = 'asc';
  }
}


// Pagination
const startResultIndex = ref(1)

const endResultIndexUncut = ref(50)
const endResultIndex = computed(() => {
  if (sortedRows.value.length < endResultIndexUncut.value) return sortedRows.value.length
  return endResultIndexUncut.value;
});

const pages = computed(() => {
  const pages = Math.ceil(sortedRows.value.length / 50);
  if (pages === 0) return 1;
  return pages;
});

const currentPage = computed(() => {
  return Math.ceil(startResultIndex.value / 50);
});

watch(pages, () => {
  startResultIndex.value = 1;
  endResultIndexUncut.value = 50;
});

const setSpecificPage = (page) => {
  startResultIndex.value = (page - 1) * 50 + 1;
  endResultIndexUncut.value = page * 50;
}

const prevPage = () => {
  if (startResultIndex.value > 1) {
    endResultIndexUncut.value = startResultIndex.value - 1;
    startResultIndex.value = startResultIndex.value - 50;

  }
}

const nextPage = () => {
  if (endResultIndex.value < sortedRows.value.length) {
    startResultIndex.value = endResultIndex.value + 1;
    endResultIndexUncut.value = endResultIndex.value + 50;
  }
}

const firstPage = () => {
  startResultIndex.value = 1;
  endResultIndexUncut.value = 50;
}

const lastPage = () => {
  if (pages.value === 1) return;
  endResultIndexUncut.value = sortedRows.value.length;
  startResultIndex.value = Math.floor((sortedRows.value.length - 1) / 50) * 50 + 1;
}

const visibleRows = computed(() => {
  return sortedRows.value.slice((startResultIndex.value - 1), endResultIndex.value);
});


// Click Handlers (Emit events based on the types of click)
const handleClick = (row) => {
  if (props.isClickable) emit('click-primary', row)
}

const handleButtonPrimaryClicked = (row) => {
  emit('click-primary', row)
}

const handleButtonSecondaryClicked = (row) => {
  emit('click-secondary', row)
}



// Selectiing Rows
const selectAll = ref(false);
const selectAllRows = () => { sortedRows.value.forEach(row => row.selected = selectAll.value) }
watch(selectAll, () => selectAllRows());
const deselectAll = () => { selectAll.value = false; selectAllRows(); }

// Exporting Selected Rows
const loadingExport = ref(false);
const selectedRows = computed(() => sortedRows.value.filter(row => row.selected));
const handleExport = () => {
  loadingExport.value = true;
  const cols = visibleColumns.value;

  let csvContent = cols.map(col => col.label).join(",") + "\n";

  selectedRows.value.forEach(row => {
    let rowContent = cols.map(col => {
      let value = getNestedValue(row, col.key);
      if (value === null || value === undefined) {
        value = '-';
      }
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""'); // Escape double quotes
      }
      return `"${value}"`;
    }).join(",");
    csvContent += rowContent + "\n";
  })

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${props.export_title}_table_data.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  loadingExport.value = false;
}

// This function is used to get nested values from an object to display in the columns
const getNestedValue = (obj, path) => { // Utility function to get nested values
  if (path === '') return obj;
  return path.split('.').reduce((acc, part) => {
    // Handle array indices in the path
    const match = part.match(/^(.+)\[(\d+)\]$/);
    if (match) {
      const arrayKey = match[1];
      const index = parseInt(match[2], 10);
      return acc && acc[arrayKey] && acc[arrayKey][index];
    }
    return acc && acc[part];
  }, obj);
}
</script>