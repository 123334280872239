<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="rounded-full bg-operacolor hover:bg-operacolorlight w-fit p-2">
        <PlusIcon class="size-6 text-white" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-neutral-700 rounded-md bg-white dark:bg-neutral-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }">
          <a @click="handleEmit('paragraph')"
            :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
            <Bars3BottomLeftIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
            Add a paragraph
          </a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
          <a @click="handleEmit('header')"
            :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
            <H1Icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
            Add a Header
          </a>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
          <a @click="handleEmit('image')"
            :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
            <PhotoIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
            Add an image
          </a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
          <a @click="handleEmit('link')"
            :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
            <LinkIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
            Add a Link
          </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  PhotoIcon,
  Bars3BottomLeftIcon,
  LinkIcon,
  H1Icon,
  PlusIcon
} from '@heroicons/vue/20/solid'

const emit = defineEmits(['image', 'header', 'paragraph', 'link'])
const handleEmit = (type) => { emit(type) }
</script>