<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />

    <!-- UpdateTrade Popup -->
    <UpdateTrade v-if="openTransactionStatusEditor" :show="openTransactionStatusEditor" v-model="transaction" @closed="openTransactionStatusEditor = false"
      @updated="notify('success', 'Success', 'Transaction updated successfully'); setAllTransactions()"
      @error="notify('failure', 'Sorry', 'Something unexpected happened, please try again, or contact tech support')" />
    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Transactions Table -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Transactions</h3>
          <!-- Search -->
          <SearchInput v-model="query" />
        </div>
        <div class="flex">
          <TableOrCard v-model="isTable" />
        </div>
      </div>

      <!-- Transactions Table -->
      <TableSticky v-if="isTable" :loading="loading_transactions" :loading_all="loading_all_transactions" :rows="filteredTransactions" v-model="columns" :isClickable="clickable"
        @click-primary="handleTransactionClicked" class="-mt-8" export_title="transactions" />

      <!-- Transaction Card -->
      <div v-else>
        <ul role="list" class="flex gap-10 flex-wrap mt-4">
          <div v-for="transaction in filteredTransactions" :key="transaction.id" @click="handleTransactionClicked(transaction)" class="w-96 mt-2">
            <TransactionCard :transaction="transaction" :clickable="clickable" />
          </div>
        </ul>
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue'

// Stores
import { useUserStore } from '@/stores/user'
const userStore = useUserStore();
import { usePartnerStore } from '@/stores/partner'
const partnerStore = usePartnerStore();
import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore();

// Components
import UpdateTrade from '@/components/popups/UpdateTrade.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue'
import TableSticky from '@/components/applicationui/TableSticky.vue'
import TransactionCard from '@/components/cards/TransactionCard.vue'
import TableOrCard from '@/components/ui/TableOrCard.vue'
import SearchInput from '@/components/applicationui/SearchInput.vue'
// Libraries

// Table Display
const loading = ref(true)
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false
const loading_transactions = ref(true)
const loading_all_transactions = ref(true)
const clickable = ref(false)
const columns = ref([
  { key: 'offerings.name', label: 'Offering', type: 'bold', visible: true },
  { key: 'tapi_trade_id', label: 'Trade #', type: 'copy', visible: false },
  { key: 'status', label: 'Status', type: 'badge', visible: true },
  { key: 'type', label: 'Type', type: 'badge', visible: true },
  { key: 'accounts.name', label: 'Account', type: 'bold', visible: true },
  { key: 'transaction_method', label: 'Method', type: 'badge', visible: true },
  { key: 'amount', label: '# of Units', type: 'defualt-fill', visible: false },
  { key: 'price_per_unit', label: 'Price', type: 'money', visible: false },
  { key: 'amount', key2: 'price_per_unit', label: 'Total Value', type: 'multiply', visible: true },
  { key: 'units', label: 'Units', type: 'badge', visible: true },
  { key: 'created_at', label: 'Created Date', type: 'date', visible: true },
])

// Search
const query = ref('');
const filteredTransactions = computed(() => {
  return query.value === '' ? transactions.value :
    transactions.value.filter((transaction) => {
      if (transaction?.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.offerings?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.status?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.type?.toLowerCase().includes(query.value.toLowerCase())) return true
      if (transaction?.tapi_trade_id?.includes(query.value)) return true;
      if (transaction?.account_id?.includes(query.value.toLowerCase())) return true;
      if (transaction?.accounts?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.units?.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});


// Transactions
const transactions = ref([])
const transaction = ref({})
const openTransactionStatusEditor = ref(false)

async function setAllTransactions() {
  const data = await transactionsStore.getAllTransactionsPaginated(0, 49)
  loading_transactions.value = false
  if (!data) {
    notify('failure', 'Sorry', 'Failed to get transactions, please try again later, or contact tech support')
    loading_all_transactions.value = false
    return
  }
  transactions.value = data
  getRestOfTransactions()
}

async function getRestOfTransactions() {
  const data = await transactionsStore.getAllTransactionsPaginated(50)
  if (!data) { loading_all_transactions.value = false; return }
  transactions.value = transactions.value.concat(data)
  loading_all_transactions.value = false
}

const handleTransactionClicked = (t) => {
  transaction.value = t
  if (userStore.user.profiles_protected.role == 'super_admin' || partnerStore.partner.partners_protected.trade_status_admin) openTransactionStatusEditor.value = true
}

// Notifications
const notification = ref(null)
const notificationType = ref('success')
const notificationTitle = ref('Success')
const notificationText = ref('Transaction updated successfully')

const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}

// Mounted
onMounted(async () => {
  // Setup
  if (userStore.user.profiles_protected.role == 'super_admin' || partnerStore.partner.partners_protected.trade_status_admin) clickable.value = true
  if (userStore.user.profiles_protected.role == 'super_admin') columns.value.splice(columns.value.length - 1, 0, { key: 'partners', label: 'Partner', type: 'partner-logo', visible: true });
  // Get Transactions
  await setAllTransactions()
  // Ready
  loading.value = false
})
</script>
