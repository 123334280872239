<template>
  <div>
    <NotificationPopup :type="notficationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- DealRoom -->
    <div v-if="showDealRoom">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>

    <!-- Offers Table -->
    <div v-else class="px-4 sm:px-6 lg:px-8">
      <!-- Loading -->
      <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
        <div class="w-32 h-32 -mt-10">
          <SpinnerFlip />
        </div>
      </div>

      <!-- Empty State -->
      <div v-else-if="offerings.length <= 0">
        <div class="text-center">
          <GlobeAmericasIcon class="h-12 w-12 mx-auto text-gray-400" />
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Available Offerings</h3>
          <p class="mt-2 text-sm text-gray-500 w-72 mx-auto">It appears you do not have access to any offerings. If this is unexpected, please contact us at support@operaalts.com, or our partners via
            their support lines.</p>
        </div>
      </div>

      <!-- Offers Table -->
      <div v-else class="flex flex-col gap-5">

        <!-- Header -->
        <div>
          <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
            <!-- Tab Header -->
            <h3 class="primary_header">Offerings</h3>

            <div class="flex items-center gap-4">
              <!-- Search -->
              <div class="flex items-center gap-2">
                <InfoCircle info="Search by title, market sector, asset class or score (if available)" class="" />
                <div>
                  <div class="relative rounded-md">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                    </div>
                    <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <Switch v-model="isTable" label="Table View" size="small" />
        </div>

        <!-- Accounts Table -->
        <div v-if="isTable">
          <TablePrimary :rows="filteredOfferings" :columns="columns" :isClickable="true" @click-primary="showOfferingDetail" :allow_export="false" />
        </div>

        <!-- Card View -->
        <div v-else class="flex flex-wrap gap-10 justify-center sm:justify-start">
          <!-- Cards -->
          <div class="shadow-[0px_2px_4px_2px_rgba(0,0,0,0.14)] h-fit w-80 box-border flex justify-between flex-col rounded-lg dark:bg-neutral-800 dark:text-neutral-300"
            v-for="(o) in filteredOfferings" :key="o.id">
            <OfferingCard :offering="o" :partner="partnerStore.partner" :allow_edit="false" @button-clicked="showOfferingDetail" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { usePartnerStore } from '@/stores/partner';
import { useOfferingsStore } from '@/stores/offerings';
import { useUserStore } from '@/stores/user';
// Components
import Switch from '@/components/applicationui/Switch.vue';
import TablePrimary from '@/components/widgets/TablePrimary.vue';
import OfferingCard from '@/components/cards/OfferingCard.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import InfoCircle from '@/components/ui/InfoCircle.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue';
// Libraries
import { MagnifyingGlassIcon, GlobeAmericasIcon } from '@heroicons/vue/24/outline'
// Stores
const partnerStore = usePartnerStore();
const offeringsStore = useOfferingsStore();
const userStore = useUserStore();
// Globals
const router = useRouter();
const route = useRoute();
const loading = ref(true);
const offerings = ref([]);
const showDealRoom = ref(false);
const isTable = ref(false);
const query = ref('');
const scoreText = ref('Opera');
const notification = ref(null)
const notficationType = ref('success')
const notificationTitle = ref('Success')
const notificationText = ref('')
const columns = [
  { key: 'name', label: 'Name' },
  { key: 'market_sector', label: 'Sector', type: 'capitalize' },
  { key: 'slogan', label: 'Description' }
];
// Computed
const filteredOfferings = computed(() => {
  return query.value === '' ? offerings.value :
    offerings.value.filter((offering) => {
      if (offering.name.toLowerCase().includes(query.value.toLowerCase())) return true
      if (offering.offering_type.toLowerCase().includes(query.value.toLowerCase())) return true
      if (offering.score.toLowerCase().includes(query.value.toLowerCase())) return true
      if (offering.market_sector.toLowerCase().includes(query.value.toLowerCase())) return true
    });
});

// Mounted
onMounted(async () => {
  // Get offerings
  await setOfferings();
  // If you are a DIB partner, show the first offering's dealroom
  if (partnerStore.partner.partners_protected.partner_type === 'dib') showOfferingDetail(offerings.value[0])
  if (partnerStore.partner.partners_protected.partner_type === 'custody' && offerings.value?.length == 1) showOfferingDetail(offerings.value[0])
  // Set score text if needed
  if (partnerStore.partner.partner_scores && partnerStore.partner.show_scores_for_advisors) scoreText.value = partnerStore.partner.title
  // Ready
  loading.value = false;
});

// Functions
async function setOfferings() {
  offerings.value = await offeringsStore.getAllApprovedOfferingsForPartner(partnerStore.partner.id, userStore.user);
  if (!offerings.value) notify('failure', 'Sorry', 'We were unable to get available offerings. Please try again, if the issue persists contact support');
};

// Simple Functions
const showOfferingDetail = (offering) => {
  router.push({ path: `/dashboard/investing/deal/${offering.id}` });
  showDealRoom.value = true;
};

const notify = (type, title, text) => {
  notficationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}

// Watchers
watch(route, (to) => {
  if (to.path == '/dashboard/investing' || to.path == '/dashboard/investing/') { showDealRoom.value = false; window.scrollTo(0, 0) }
  else { showDealRoom.value = true; window.scrollTo(0, 0); loading.value = false; }
}, { immediate: true });
</script>