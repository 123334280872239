<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="">
        <!-- <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400 dark:text-neutral-300" aria-hidden="true" /> -->
        <ButtonSecondary text="Customize" size="lg" :icon="WrenchIcon" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute right-0 z-10 mt-2 w-fit h-fit origin-top-right rounded-md bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

        <div class="px-5 py-4 flex flex-col gap-5 w-full">
          <fieldset>
            <legend class="text-base font-semibold leading-6 text-gray-900 dark:text-white flex gap-1 items-center">
              Visible Columms
            </legend>
            <div class="mt-4 divide-y max-h-96 divide-gray-200 border-t border-gray-200 dark:border-neutral-700 dark:divide-neutral-700 overflow-y-scroll">
              <div v-for="column in columns" class="relative flex items-start py-4 min-w-52">
                <Checkbox :label="column?.label" v-model="column.visible" class="pl-1" />
              </div>
            </div>
          </fieldset>
        </div>

      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
// Libraries
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import Checkbox from '@/components/applicationui/Checkbox.vue';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { WrenchIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  // title: { type: String, required: true, default: 'Select' },
  // items: { type: Array, required: true, default: null },
})

const columns = defineModel()

// Emits
// const emit = defineEmits(['selected'])
// Simple Functions
// const handleClick = (item) => { emit('selected', item) }
</script>