<template>
  <button :type="props.type" :disabled="(props.disabled || props.loading)" class=""
    :class="[(props.loading) ? 'bg-opacity-70 bg-gray-100' : 'bg-white hover:bg-gray-100 dark:hover:bg-neutral-700', `${getButtonClass(props.size)} inline-flex items-center justify-center font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-0 dark:bg-neutral-800 dark:text-white focus:outline-emerald-600`]">
    <!-- <div v-if="props.loading" class="absolute">
      <IconLoaderCircle class="animate-spin h-5 w-5 text-gray-600" aria-hidden="true" />
    </div> -->
    <div :class="[props.loading ? 'opacity-0' : '', 'flex items-center gap-1']">
      <component v-if="props.iconFirst && props.icon" :is="props.icon" :class="getIconSize(props.size)" aria-hidden="true" />
      {{ props.text }}
      <component v-if="!props.iconFirst && props.icon" :is="props.icon" :class="getIconSize(props.size)" aria-hidden="true" />
    </div>
  </button>
</template>


<script setup>
// Props
const props = defineProps({
  size: { type: String, required: false, default: 'md' },
  text: { type: String, required: true, default: 'Button' },
  icon: { required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
  iconFirst: { type: Boolean, required: false, default: false },
  loading: { type: Boolean, required: false, default: false },
  type: { type: String, required: false, default: 'button' },
})
// Functions
const getButtonClass = (size) => {
  if (size === 'xs') return 'rounded px-2 py-1 text-xs gap-x-2'
  if (size === 'sm') return 'rounded px-2 py-1 text-sm gap-x-2'
  if (size === 'md') return 'rounded-md px-2.5 py-1.5 text-sm gap-x-1.5'
  if (size === 'lg') return 'rounded-md px-3 py-2 text-sm gap-x-1.5'
  if (size === 'xl') return 'rounded-md px-3.5 py-2.5 text-sm gap-x-2'
}
const getIconSize = (size) => {
  if (size === 'xs') return 'size-3'
  if (size === 'sm') return 'size-4'
  if (size === 'md') return 'size-5'
  if (size === 'lg') return 'size-5'
  if (size === 'xl') return 'size-5'
}
</script>
