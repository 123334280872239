<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-neutral-500 dark:bg-opacity-25 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-neutral-950 py-6 shadow-xl">

                  <!-- Header -->
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                        <h3 class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">{{ props.party.first_name + ' ' + props.party.last_name }}</h3>
                        <div class="text-gray-400 dark:text-neutral-400 truncate">{{ props.party.contact_email }}</div>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300 focus:outline-none" @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Header -->

                  <!-- Content -->
                  <div class="relative mt-6 flex-1 flex flex-col justify-between">

                    <div class="sm:mx-6 mx-4 flex flex-col gap-10">
                      <div class="flex flex-col gap-10 mt-5">
                        <!-- KYC/AML Docs -->
                        <InvestorDocuments v-if="!partnerStore.partner.partners_protected.kyc_disabled" :party="props.party" :user_id="props.user_id"
                          :partner_type="partnerStore.partner.partners_protected.partner_type" />
                        <!-- Entity Documents -->
                        <EntityDocuments v-if="props.party.accounts[0].type == 'entity'" :party="props.party" :user_id="props.user_id" />
                        <!-- Accredited Investors Docs -->
                        <AccreditedDocuments :account="props.party.accounts[0]" :user_id="props.user_id" :partner_type="partnerStore.partner.partners_protected.partner_type" />

                      </div>

                      <!-- Update Accreditation Status Super Admin Only -->
                      <div v-if="userStore.user.profiles_protected.role == 'super_admin'"
                        class="flex flex-wrap gap-5 p-5 rounded-lg bg-gradient-to-r from-purple-100 via-purple-200 to-purple-100 dark:from-purple-600 dark:via-purple-700 dark:to-purple-600 shadow">
                        <div class="flex-1 flex justify-between gap-10 flex-wrap items-end">
                          <div class="w-52">
                            <div class="select_menu_label !flex items-center gap-2">Accreditation Status
                              <Tooltip content="Changing this status should only be done when correcting an out of sync status with the associated TAPI status. Please use caution with this.">
                                <QuestionMarkCircleIcon class="size-4 text-gray-400 dark:text-neutral-400" />
                              </Tooltip>
                            </div>
                            <SelectMenu v-model="accreditationStatus" :items="['Accredited', 'Under Review', 'Action Required', 'Not Accredited']" />
                          </div>
                          <div @click="updateAccreditationStatus(props.party.accounts[0].id, accreditationStatus)" class="button_yellow h-fit">
                            Save Changes
                            <SpinnerButton v-if="updatingAccreditedStatus" />
                            <CloudArrowUpIcon v-else class="w-5 h-5" />
                          </div>
                        </div>
                      </div>

                      <!-- Update KYC AML -->
                      <div v-if="!partnerStore.partner.partners_protected.kyc_disabled" class="flex flex-wrap gap-5 p-5 rounded-lg dark:bg-neutral-900 bg-white shadow">
                        <div class="flex-1 flex gap-10 flex-wrap items-end">
                          <div class="w-44">
                            <div class="select_menu_label">KYC Status</div>
                            <SelectMenu v-model="kyc" :items="kycAmlOptions" />
                          </div>
                          <div class="w-44">
                            <div class="select_menu_label">AML Status</div>
                            <SelectMenu v-model="aml" :items="kycAmlOptions" />
                          </div>
                          <div @click="updatingKycAmlStatuses()" class="button_primary h-fit">
                            Save Changes
                            <SpinnerButton v-if="updating" />
                            <CloudArrowUpIcon v-else class="w-5 h-5" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="sm:mx-6 mx-4">
                      <div class="flex justify-end gap-5 flex-wrap">
                        <div class="button_secondary" @click="requestManualReview(props.party, partnerStore.partner.title)">
                          Request Opera Manual Review
                          <SpinnerButton v-if="requestingReview" />
                          <MegaphoneIcon v-else class="w-4 h-4" />
                        </div>
                        <div class="button_secondary" @click="runKycAml(props.party.id, props.party.tapi_party_id)">
                          Run KYC & AML
                          <SpinnerButton v-if="runningKycAml" />
                          <ArrowPathIcon v-else class="w-4 h-4" />
                        </div>
                        <div class="button_secondary" @click="runAml(props.party.id, props.party.tapi_party_id)">
                          Run AML
                          <SpinnerButton v-if="runningAml" />
                          <ShieldCheckIcon v-else class="w-4 h-4" />
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- Content -->

                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted } from 'vue'
import { useUserStore } from '@/stores/user'
import { usePartnerStore } from '@/stores/partner'
import { usePartiesStore } from '@/stores/parties'
import { useEmailsStore } from '@/stores/emails'
import { useAccountsStore } from '@/stores/accounts'
// Components
import Tooltip from '@/components/applicationui/Tooltip'
import SelectMenu from '@/components/applicationui/SelectMenu'
import InvestorDocuments from '@/components/documents/InvestorDocuments'
import EntityDocuments from '@/components/documents/EntityDocuments'
import AccreditedDocuments from '@/components/documents/AccreditedDocuments'
import SpinnerButton from '@/components/loading/SpinnerButton.vue'
// Libraries
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, ArrowPathIcon, ShieldCheckIcon, MegaphoneIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import { CloudArrowUpIcon } from '@heroicons/vue/24/solid'
// Props
const props = defineProps({
  show: { type: Boolean, required: true, default: false },
  party: { type: Object, required: true, default: null },
  user_id: { type: String, required: true, default: null }
})
// Emits
const emit = defineEmits(['closed', 'success', 'error'])
// Stores
const userStore = useUserStore()
const partiesStore = usePartiesStore()
const accountsStore = useAccountsStore()
const partnerStore = usePartnerStore()
const emailsStore = useEmailsStore()
// Globals
const open = ref(false)
const updating = ref(false)
const updatingAccreditedStatus = ref(false)
const runningKycAml = ref(false)
const runningAml = ref(false)
const requestingReview = ref(false);
const kyc = ref('disapproved')
const aml = ref('disapproved')
const accreditationStatus = ref(props.party.accounts[0].accounts_protected.accredited_status)
const kycAmlOptions = ref([
  'approved',
  'disapproved',
  'pending'
])

// Mounted
onMounted(async () => {
  open.value = props.show
  kyc.value = props.party?.parties_protected?.kyc ?? 'disapproved'
  aml.value = props.party?.parties_protected?.aml ?? 'disapproved'
})

// Functions
async function updatingKycAmlStatuses() {
  updating.value = true
  const successful = await partiesStore.updateKycAndAmlStatus(props.party.id, kyc.value, aml.value)
  if (!successful) { emit('error'); return }
  updating.value = false
  open.value = false
  emit('success')
}

async function updateAccreditationStatus(account_id, accredited_status) {
  updatingAccreditedStatus.value = true
  const successful = await accountsStore.updateAccreditationStatus(account_id, accredited_status)
  if (!successful) { emit('error'); return }
  updatingAccreditedStatus.value = false
  open.value = false
  emit('success')
}

async function runKycAml(id, tapi_party_id) {
  runningKycAml.value = true
  const successful = await partiesStore.performKycAml(id, tapi_party_id)
  runningKycAml.value = false
  if(!successful) { emit('error'); return }
  emit('success')
}

async function runAml(id, tapi_party_id) {
  runningAml.value = true
  const successful = await partiesStore.performAml(id, tapi_party_id)
  runningAml.value = false
  if (!successful) { emit('error'); return }
  emit('success')
}

async function requestManualReview(party, partner_title) {
  requestingReview.value = true
  // TODO add this function to the parties store
  const successful = await emailsStore.requestManualReview(party, partner_title)
  requestingReview.value = false
  if (!successful) { emit('error'); return }

  // TODO call kyc aml function
  if (partnerStore.partner.partners_protected.managed_kyc_aml) {
    const success = partiesStore.requestManualKycAmlReview(party.id, party.tapi_party_id)
    if (!success) { emit('error'); return }
  }

  emit('success')
}

// Watchers
watch(open, async (value) => {
  if (!value) {
    await new Promise(resolve => setTimeout(resolve, 400))
    emit('closed')
  }
})
</script>
