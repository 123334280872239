import { createRouter, createWebHistory } from 'vue-router'

// Public
import VerifyUser from '@/views/public/VerifyUser.vue'
import InvalidLink from '@/views/public/InvalidLink.vue'
import LandingPage from '@/views/public/LandingPage.vue'
import LoginView from '../views/public/LoginView.vue'
import PublicOfferings from '@/views/public/PublicOfferings.vue'
import PageNotFound from '@/components/other/PageNotFound.vue'
// Dashboard
import DashboardView from '../views/dashboard/DashboardView.vue'
import LandingView from '../views/dashboard/LandingView.vue'
// Investor
import HoldingsView from '../views/dashboard/investor/HoldingsView.vue'
import PaymentMethodsView from '../views/dashboard/investor/PaymentMethodsView.vue'
import DocumentsView from '../views/dashboard/investor/DocumentsView.vue'
import AccountsView from '../views/dashboard/investor/AccountsView.vue'
import InvestView from '../views/dashboard/investor/InvestView.vue'
import EducationView from '../views/dashboard/investor/EducationView.vue'
import SettingsView from '../views/dashboard/investor/SettingsView.vue'
import DealRoom from '@/views/dashboard/investor/deals/DealRoom.vue'
import ThankYou from '@/views/dashboard/investor/deals/ThankYou.vue'
import ArticleView from '@/views/public/ArticleView.vue'
// Advisor
import ManagedAccountsView from '../views/dashboard/advisor/ManagedAccountsView.vue'
import PartiesView from '../views/dashboard/advisor/PartiesView.vue'
// Partner Admin
import OfferingsView from '../views/dashboard/partner_admin/OfferingsView.vue'
import CustodyView from '../views/dashboard/partner_admin/CustodyView.vue'
import TransactionsView from '../views/dashboard/partner_admin/TransactionsView.vue'
import InvestorsView from '../views/dashboard/partner_admin/InvestorsView.vue'
import InvestorVerification from '../views/dashboard/partner_admin/InvestorVerification.vue'
import UsersView from '../views/dashboard/partner_admin/UsersView.vue'
import SiteSettingsView from '../views/dashboard/partner_admin/SiteSettingsView.vue'
import ArticlesView from '@/views/dashboard/partner_admin/ArticlesView.vue'
// Super Admin
import PartnersView from '../views/dashboard/super_admin/PartnersView.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'signup',
    component: LoginView
  },
  {
    path: '/thankyou/:id',
    name: 'thankyou',
    component: ThankYou,
    meta: { requiresAuth: true },
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyUser,
  },
  {
    path: '/invalid-link',
    name: 'invalid-link',
    component: InvalidLink,
  },
  {
    path: '/landing',
    name: '/landing',
    component: LandingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/article/:id',
    name: 'article',
    component: ArticleView,
  },
  {
    path: '/dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'accounts',
        name: 'accounts',
        component: AccountsView,
        meta: { requiresAuth: true, id: 6 },
      },
      {
        path: 'managed-accounts',
        name: 'managed-accounts',
        component: ManagedAccountsView,
        meta: { requiresAuth: true, id: 11 },
      },
      {
        path: 'holdings',
        name: 'holdings',
        component: HoldingsView,
        meta: { requiresAuth: true, id: 2 },
      },
      {
        path: 'payment-methods',
        name: 'payment-methods',
        component: PaymentMethodsView,
        meta: { requiresAuth: true, id: 3 },
      },
      {
        path: 'documents',
        name: 'documents',
        component: DocumentsView,
        meta: { requiresAuth: true, id: 4 },
      },
      {
        path: 'investing',
        name: 'investing',
        component: InvestView,
        meta: { requiresAuth: true, id: 1 },
        children: [
          {
            path: 'deal/:id',
            name: 'deal',
            component: DealRoom,
            meta: { requiresAuth: true, id: 1 },
          },
        ]
      },
      {
        path: 'education',
        name: 'education',
        component: EducationView,
        meta: { requiresAuth: true, id: 5 },
      },
      {
        path: 'parties',
        name: 'parties',
        component: PartiesView,
        meta: { requiresAuth: true, id: 12 },
      },
      {
        path: 'offerings',
        name: 'offerings',
        component: OfferingsView,
        meta: { requiresAuth: true, id: 24 },
      },
      {
        path: 'custody',
        name: 'custody',
        component: CustodyView,
        meta: { requiresAuth: true, id: 26 },
      },
      {
        path: 'investor-verification',
        name: 'investor-verification',
        component: InvestorVerification,
        meta: { requiresAuth: true, id: 27 },
      },
      {
        path: 'investors',
        name: 'investors',
        component: InvestorsView,
        meta: { requiresAuth: true, id: 22 },
      },
      {
        path: 'users',
        name: 'users',
        component: UsersView,
        meta: { requiresAuth: true, id: 21 },
      },
      {
        path: 'partners',
        name: 'partners',
        component: PartnersView,
        meta: { requiresAuth: true, id: 31 },
      },
      {
        path: 'site-settings',
        name: 'site-settings',
        component: SiteSettingsView,
        meta: { requiresAuth: true, id: 25 },
      },
      {
        path: 'admin-transactions',
        name: 'admin-transactions',
        component: TransactionsView,
        meta: { requiresAuth: true, id: 23 },
      },
      {
        path: 'articles',
        name: 'articles',
        component: ArticlesView,
        meta: { requiresAuth: true, id: 28 },
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/public/:id',
    name: 'public',
    component: PublicOfferings,
    meta: { requiresAuth: false },
  },
  { path: '/:catchAll(.*)', component: PageNotFound } //Catch all 404
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Protecting routes
import { usePartnerStore } from '@/stores/partner'
import { useUserStore } from '@/stores/user'
import { useAccountsStore } from '@/stores/accounts'

router.beforeEach(async (to, from, next) => {
  if (to.fullPath == '/landing#error=access_denied&error_code=403&error_description=Email+link+is+invalid+or+has+expired') {
    next('/invalid-link');
    return
  }

  const partnerStore = usePartnerStore()
  const userStore = useUserStore()
  const accountsStore = useAccountsStore()
  
  if (!userStore.user) await userStore.setUser()
  if (!partnerStore.partner) await partnerStore.setPartner()
  if (!accountsStore.holdingAccount) await accountsStore.setHoldingAccount()

  // If the route requires auth and the user is not logged in, redirect to login
  if (to.meta.requiresAuth && !userStore.user) { next('/login'); return }

  // If the route has an id, but the user is not allowed to access the route, redirect to settings
  if (to.meta.id) {
    if (userStore.user.profiles_protected.role === 'investor' && partnerStore.partner.partners_protected.investor_tabs.includes(to.meta.id)) { next(); return }
    if (userStore.user.profiles_protected.role === 'advisor' && (partnerStore.partner.partners_protected.investor_tabs.includes(to.meta.id) || partnerStore.partner.partners_protected.advisor_tabs.includes(to.meta.id))) { next(); return }
    if (userStore.user.profiles_protected.role === 'partner_admin' && partnerStore.partner.partners_protected.admin_tabs.includes(to.meta.id)) { next(); return }
    if (userStore.user.profiles_protected.role === 'super_admin') { next(); return }
    next('/dashboard/settings');
    return
  }
  next()
})

export default router
