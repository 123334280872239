<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button type="button" class="text-gray-400 rounded-md hover:text-gray-500 dark:hover:text-neutral-300 dark:text-neutral-400" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto dark:bg-yellow-950 bg-yellow-100  rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon class="w-6 h-6 text-yellow-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white">Delete Connected Bank Account</DialogTitle>
                  <div class="flex flex-col gap-2 mt-2">
                    <p class="text-sm text-gray-500 dark:text-neutral-400">Are you sure you want to delete your connected bank account. Doing so may interrupt recent investments that are still
                      pending.</p>
                  </div>
                </div>
              </div>
              <div class="flex gap-4 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="button_yellow" @click="handleConfirmClick()">
                  Delete
                </button>
                <button type="button" class="button_secondary" @click="open = false">Cancel</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Libraries
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
// Emits
const emit = defineEmits(['confirmed'])
// Model
const open = defineModel(false)
// Simple Functions
const handleConfirmClick = () => {
  open.value = false
  emit('confirmed')
}
</script>