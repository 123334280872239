<template>
  <form @submit.prevent="sendNewUserInvitation()">
    <div class="flex items-start w-fit">
      <div class="flex items-center justify-center flex-shrink-0 mx-auto bg-green-100 dark:bg-green-950 rounded-full h-10 w-10">
        <UserPlusIcon class="w-6 h-6 text-green-600" aria-hidden="true" />
      </div>
      <div class="flex-shrink mt-2 mr-8 ml-4 text-left">
        <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white">New Users Role</DialogTitle>

        <div class="my-5 flex flex-col gap-5 w-80">

          <!-- Partner -->
          <div v-if="props.currentUserRole == 'super_admin'">
            <div class="select_menu_label">Associated Partner</div>
            <SelectMenu v-model="selectedPartner" :items="partners" />
          </div>

          <!-- Role -->
          <div>
            <div class="select_menu_label">Role</div>
            <SelectMenu v-model="selectedRole" :items="roles" />
          </div>

          <!-- Email -->
          <Input v-model="email" label="Email" id="email" type="email" autocomplete="email" placeholder="user@example.com" :required="true" />

          <!-- Verify Email -->
          <Input v-model="verify_email" label="Verify Email" id="verify_email" type="email" autocomplete="email" placeholder="user@example.com" :required="true" />

        </div>

      </div>
    </div>
    <div class="flex gap-4 w-full justify-end items-center mt-5">
      <ButtonSecondary @click="emit('close')" text="Cancel" />
      <ButtonPrimary type="submit" text="Send Invitation" :loading="buttonSpinner" />
    </div>
  </form>
</template>

<script setup>
// Essentials
import { onMounted, ref } from 'vue'
import { useUserStore } from '@/stores/user';
import { useProfilesStore } from '@/stores/profiles';
import { usePartnerStore } from '@/stores/partner';
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import Input from '@/components/applicationui/Input.vue';
// Libraries
import { UserPlusIcon } from '@heroicons/vue/24/outline'
import { DialogTitle } from '@headlessui/vue'

// Props
const props = defineProps({
  currentUserRole: { type: String, required: null },
  currentUserPartner: { type: Object, required: true }
})

// Emits
const emit = defineEmits(['failure', 'success', 'emails-dont-match', 'close'])

// Stores
const userStore = useUserStore();
const profilesStore = useProfilesStore();
const partnerStore = usePartnerStore();

// Globals
const selectedPartner = ref({ ...props.currentUserPartner, label: props.currentUserPartner.title })
const email = ref('')
const verify_email = ref('')
const buttonSpinner = ref(false)

// Partners
const partners = ref([])
async function setPartners() {
  partners.value = await partnerStore.getAllPartners()
  // Add a label to each partner that is equal to the partner's title (For SelectMenu in InviteUser)
  partners.value.forEach(partner => { partner.label = partner.title });
}

// Roles
const roles = ref([
  { id: 1, label: 'Investor', value: 'investor' },
  { id: 3, label: 'Admin', value: 'partner_admin' }
])
const selectedRole = ref(roles.value[0])

async function sendNewUserInvitation() {
  buttonSpinner.value = true

  // Check that emails match
  if (email.value !== verify_email.value) {
    emit('emails-dont-match')
    buttonSpinner.value = false
    return
  }

  // If they selected a partner admin role, add the email to the pending list
  if (selectedRole.value.value == 'partner_admin') {
    const successful = await profilesStore.addEmailToPendingPartnerAdmins(email.value)
    if (!successful) {
      emit('failure')
      buttonSpinner.value = false
      return
    }
  }


  // Send the magic link
  const error = await userStore.sendMagicLink(email.value, `${window.location.origin}/landing`, selectedPartner.value, window.location.origin, partnerStore.getLogoByMetaName(selectedPartner.value.meta_name), 'magic-link')

  // Handle error
  if (error) {
    buttonSpinner.value = false
    emit('failure')
    return
  }

  // Handle sucess
  buttonSpinner.value = false
  emit('success')
  open.value = false;
}

onMounted(async () => {
  if (partnerStore.partner.allow_advisors) roles.value.push({ id: 3, label: 'Advisor', value: 'advisor' })

  if (props.currentUserRole == 'super_admin') {
    roles.value[1].label = 'Partner Admin'
    await setPartners()
  }

})
</script>