<template>
  <div>
    <div class="button_secondary m-5" @click="goBack()">
      <ArrowLeftIcon class="w-4 h-4" />
      Back
    </div>

    <div class="w-full flex items-center justify-center">

      <div class="flex flex-col w-fit max-w-[1000px]">
        <div class="flex flex-wrap items-end justify-between mb-10 gap-y-5">
          <div class="text-3xl font-bold">Fund Rating Methodology</div>
          <div class="text-sm">by: Alecia Reinhardt</div>
        </div>
        <img class="w-full" :src="image" />

        <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
          <!-- Paragraph 1 -->
          <div class="text-xl font-semibold">Introduction and Overview</div>
          <div>
            This document aims to provide a general description of the procedures and methodologies used to determine ratings of different private alternative funds listed on the North Capital Opera
            platform.
            <br /><br />
            This methodology is intended as a general guidance to help funds, investors and other market participants gain additional insight into how North Capital considers both quantitative and
            qualitative factors in determining different rating outcomes. An Opera rating is not a recommendation to purchase, sell, or hold a fund or financial instrument. Opera ratings are based on
            information obtained by North Capital from the fund issuers and other sources it considers appropriate. The considerations outlined in this document are not intended to be exhaustive. See
            also
            <b>Disclaimers</b> below.
            <br /><br />
            Scoring will be assigned using a weighted average. In certain cases, a low score in one category may outweigh other categories in which the fund scores positively.
          </div>
          <!-- Paragraph 2 -->
          <div class="text-xl font-semibold">Assessment Criteria</div>
          <div>
            North Capital's rating assessments considers the following categories:
            <ul class="pl-8 list-decimal">
              <li>Management Team</li>
              <li>Assets Under Management (AUM)</li>
              <li>Fund Longevity</li>
              <li>Financials</li>
              <li>Quality of Offering Documents</li>
              <li>Liquidity and Redemption</li>
              <li>Fees and Expenses</li>
            </ul>
          </div>
          <!-- Paragraph 3 -->
          <div class="text-xl font-semibold">Major Considerations for Rating Analysis</div>
          <div class="text-lg font-semibold">Management</div>
          <div>
            The composition of the fund's management team is an important component to the fund's ability to deliver superior performance. The relevant managers and personnel of a fund are scored
            along
            several criteria:
            <ul class="pl-8 list-decimal">
              <li class="pl-2">
                <div class="font-medium">Experience and ability</div>
                <ul class="pl-2 list-disc">
                  <li class="pl-2">How many years of experience do the managers have with the current fund and with the investment strategy? This includes prior experience in specific industries such
                    as:
                    farmland or
                    cryptocurrency.</li>
                  <li class="pl-2">Do the fund managers have any related/relevant experience in managing the strategy (i.e., farmland, multi-family real estate, long/short hedge fund, etc.)?</li>
                </ul>
              </li>
              <li class="pl-2">
                <div class="font-medium">Depth of management team</div>
                <ul class="pl-2 list-disc">
                  <li class="pl-2">Does the fund rely heavily on one or two persons?</li>
                  <li class="pl-2">
                    <div class="flex items-center gap-1">
                      <div>Does the fund have key man provision with a clear replacement plan to mitigate</div>
                      <div class="italic">key person risk</div>
                      <InfoCircle :info="info" class="" />
                      <div>?</div>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="pl-2">
                <div class="font-medium">Background checks and regulatory disclosures</div>
                <ul class="pl-2 list-disc">
                  <li class="pl-2">Are the fund managers or the managing entity subject to any investor lawsuits, SEC or FINRA proceedings, or state securities proceedings?</li>
                  <li class="pl-2">Have the managers committed any bad acts as defined in <a class="text-blue-800" target="_blank"
                      href="https://www.sec.gov/info/smallbus/secg/bad-actor-small-entity-compliance-guide">the Rule
                      506(d) of
                      Regulation D?</a></li>
                  <li class="pl-2">BrokerCheck Report - If any of the managers were or are currently registered with FINRA, are there any disclosure events in the report?</li>
                  <li class="pl-2">Are the managers involved in any past or pending litigation related to the securities industry, including theft, fraud, or embezzlement?</li>
                </ul>
              </li>
            </ul>
            <div class="flex justify-center"><img class="w-2/3" :src="image1" /></div>
          </div>
          <!-- Paragraph 4 -->
          <div class="text-lg font-semibold">Assets Under Management (AUM)</div>
          <div>
            AUM reflects the market value of all the investments managed by a fund manager or financial institution.
            <br /><br />
            The AUM score considers the following:
            <ul class="pl-8 list-disc">
              <li>Fund AUM: total value of the assets managed by the fund where more assets under management yield higher ratings</li>
              <li>Total Strategy AUM: the total value of the assets managed by the fund manager within the same investment strategy (for example, Real Estate, Long/Short Hedge Fund, etc.)</li>
              <li>Cumulative Firm AUM: the value of the assets managed by the Firm across different investment strategies and portfolios</li>
            </ul>
          </div>
          <!-- Paragraph 5 -->
          <div class="text-lg font-semibold">Fund Longevity</div>
          <div>
            Fund longevity refers to how long a fund has been in operation. Fund longevity is an important factor in assessing alternative private funds; funds with greater longevity tend to operate
            through various market cycles and are more likely to continue efficient operation in the future. Accordingly, funds with greater longevity are often assigned a higher score than
            recently-established funds.
          </div>
          <div class="text-lg font-semibold">Financials</div>
          <div>
            Financial statements provide a depiction of a fund's performance over a specified time period. The Financials rating accounts for the availability of financial information, expertise of
            the
            financial preparer (i.e., whether self or third party prepared, whether audited, and if audited, whether by a PCAOB-registered firm), and the existence (or lack thereof) of any financial
            statement reviewed by a qualified party.
          </div>
          <div class="text-lg font-semibold">Offering Documents</div>
          <div>
            Offering documents refer to the materials provided to investors during the subscription process such as private placement memorandum (PPM), offering memorandums, and subscription
            agreements.
            Offering documents are reviewed for accuracy of information about the investment strategy, management team experiences and track record, fund management and performance fees, and risk
            factors.
            Offering documents that contain typographical, spelling, or other errors and lack important disclosures and risk factors will be assigned a low score.
          </div>
          <div class="text-lg font-semibold">Liquidity</div>
          <div>
            Liquidity refers to whether and how quickly the purchase or sale of fund shares may be able to take place within a secondary market or through applicable redemption programs. Funds with
            secondary markets are assigned higher scores compared to funds without secondary markets.
          </div>
          <div class="text-lg font-semibold">Fees</div>
          <div>
            Typical fees charged to fund investors may include: sales loads, 12b-1 fees, management fees, and incentive fees (among others). The Fee category takes into account fees charged across the
            fund's respective industry to provide an assessment against peer funds. Funds that are less costly to investors compared to the industry standard (see Appendix A) are deemed advantageous
            to
            investors and receive more favorable scores compared to funds with higher fees.
          </div>
          <div class="text-xl font-semibold">Gating Items and Disqualifying Factors</div>
          <div>
            Certain findings may disqualify a fund from listing on the Opera platform. Disqualifying factors include, but are not limited to: <br />
            <ul class="pl-5 list-disc">
              <li>Key persons or managers of the fund meet the definition of bad actors as defined in Rule 506(d) of Regulation D</li>
              <li>Certain SEC disciplinary orders or cease-and-desist orders</li>
              <li>Material misrepresentations or omissions of key information</li>
              <li>Unable to verify the identities of any fund control persons</li>
            </ul>
            <br />
            If a fund has a disqualifying factor present, it will not be permitted to list on the Opera platform. Additionally, if a fund experiences a disqualifying event, it is subject to removal
            from
            Opera. North Capital reserves the right to make determinations on funds that are permitted on the Opera platform.
            <div class="flex justify-center"><img class="w-2/3" :src="image2" /></div>
          </div>
          <div class="text-xl font-semibold">Rating the Fund</div>
          <div>
            North Capital uses a weighted average for the rating categories in order to determine final ratings on funds. See an example fund calculation below:
            <div class="flex mt-5"><img class="w-2/3" :src="image3" /></div>
            <div class="flex"><img class="w-2/3" :src="image4" /></div>
            <div class="flex"><img class="w-2/5" :src="image7" /></div>
          </div>
          <div class="text-xl font-semibold">Disclaimers</div>
          <div>
            An Opera rating should not be construed as an attestation of the merits of the investment, probability of investment success or the suitability of the investment for any particular
            investor.
            An Opera rating does indicate a substantive assessment of any Offering Document (including risk factors) or any financial statement. North Capital provides an Opera rating on an “as-is”
            basis
            without any representation or warranty of any kind, express or implied, as to its accuracy, consistency or thoroughness. North Capital disclaims any and all liability. An Opera rating is
            not
            advice, but a reference tool only. The Opera rating is best used as an initial screen to identify funds for further research. Any investment decision should be made based solely upon
            appropriate due diligence and, if applicable, upon receipt and careful review of any offering documents or prospectus of the fund (including consideration of risk factors). An Opera rating
            is
            not a recommendation to purchase, sell, or hold a fund or financial instrument.<br /><br />
            Opera ratings are based on information obtained by North Capital from the fund issuers and other sources it considers appropriate. Adequate information must be available for North Capital
            to reach a view on the rating of the fund in question. This includes publicly available information on the fund, such as performance statistics, reports filed with regulatory agencies, and
            other reports from third-party data providers. In addition, the rating process may incorporate data and insight gathered by North Capital, including information and documentation directly
            from
            fund issuer (e.g., financials), which North Capital does not independently verify.<br /><br />
            An Opera rating speaks as of the time it was conducted and it may no longer be current. North Capital is under no obligation to update any information in an Opera rating and an Opera
            rating
            does not take into account any information events or conditions since the time it was conducted.<br /><br />
            The weights for the category scores shown in this document are for reference only. The considerations outlined in this Rating Methodology are not intended to be exhaustive. A high rating
            does
            not imply the approval or endorsement of a fund by North Capital. It is also important to note that not all high-rated funds are equal or identical. A hedge fund rated a score of 4, for
            example, might be far riskier than a highly diversified real estate fund with a similar score.<br /><br />
            Notwithstanding any rating or availability of a secondary market, there can be no assurance of an investment return and resale may be difficult, take a significant amount of time or not be
            available.<br /><br />
            An Opera rating does not constitute an offer or solicitation to buy or sell any securities or otherwise invest in the business or assets of any fund. An Opera rating relates to the
            securities
            issued by the fund issuer and not any intermediary investment vehicle.<br /><br />
            Any forward-looking statements are not historical facts and are based on estimates, projections and expectations about the industry and other beliefs and assumptions subject to risks and
            uncertainties. Actual and future results and trends could differ materially from those described by such statements due to various factors, including those beyond any ability to control or
            predict.<br /><br />
            There are inherent conflicts of interest; North Capital receives fees. Market participants are provided with the opportunity to comment on the rating methodology
            through North Capital's website (publicly available) for consideration.
          </div>
          <div class="text-xl font-semibold">Appendix</div>
          <div class="text-lg font-semibold">Appendix A - Average Fees and Expenses</div>
          <div class="flex"><img class="w-2/3" :src="image5" /></div>
          <div class="flex"><img class="w-2/3" :src="image6" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import InfoCircle from '@/components/ui/InfoCircle.vue'
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'
import { useRouter } from 'vue-router'

const info = 'A key man is an important executive who is critical to the operation of the fund, and whose death, absence, or disability may have a significant negative effect on the success of the fund.Key person risk refers to the potential loss of value, performance, or reputation of a business due to the departure or death of an individual.'
const image = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/meetingLaptop'
const image1 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology1'
const image2 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology2'
const image3 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology3'
const image4 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology4'
const image5 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology5'
const image6 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology6'
const image7 = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/methodology7'

const router = useRouter();
const goBack = () => { router.go(-1); }
</script>