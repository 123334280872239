<template>
  <div class="flex items-center">
    <div class="relative rounded-md min-w-52">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
      </div>
      <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
    </div>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
const query = defineModel()
</script>