<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Notifactions Popup -->
    <!-- <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" /> -->

    <!-- Investor Slideover -->
    <Slideover v-model="showInvestorSlideover" :partner="selectedInvestor.partners">
      <InvestorSlideover v-model="selectedInvestor" :partner="selectedInvestor.partners" @closed="showInvestorSlideover = false" @updated="setInvestors()" />
    </Slideover>

    <!-- Investor Flow Popup -->
    <AlphaFlow v-if="showFlow" v-model="showFlow" need="create-party-for-other" @completed="showFlow = false" @closed="showFlow = false" />

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Empty State -->
    <div v-else-if="investors && investors.length <= 0" class="text-center">
      <UsersIcon class="w-12 h-12 mx-auto text-gray-400" />
      <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Clients</h3>
      <p class="mt-1 text-sm text-gray-500">You have now investors yet.<br />Once an investor has signed up you will see their information here.</p>
      <div @click="showFlow = true" class="mt-5 button_primary">Create Investor</div>
    </div>

    <!-- Show Parties -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between flex-wrap gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Investors</h3>

          <div class="flex items-center flex-wrap gap-4">
            <!-- Search -->
            <SearchInput v-model="query" />
            <!-- Create Investor -->
            <ButtonPrimary text="Create Investor" size="lg" @click="showFlow = true" :icon="UserPlusIcon" class="min-w-fit" />
          </div>
        </div>
        <div class="flex">
          <TableOrCard v-model="isTable" />
        </div>
      </div>

      <!-- Investors Table -->
      <TableSticky v-if="isTable" :loading="loading_first_investors" :loading_all="loading_all_investors" :rows="filteredInvestors" v-model="columns" :isClickable="true"
        @click-primary="handleInvestorClicked" class="-mt-8" export_title="users" />

      <!-- Investor Cards -->
      <div v-else role="list" class="flex gap-6 flex-wrap mt-4">
        <div v-for="i in filteredInvestors" :key="i.id" @click="handleInvestorClicked(i)"
          class="divide-y divide-gray-200 rounded-lg bg-white shadow-opera dark:shadow-none cursor-pointer border w-80 border-white hover:border-gray-300 hover:border dark:bg-neutral-800 dark:border-neutral-800 dark:hover:border-neutral-600">
          <InvestorCard :investor="i" :role="userStore.user.profiles_protected.role" />
        </div>
      </div>

    </div>


  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue'

// Stores
import { useUserStore } from '@/stores/user'
const userStore = useUserStore();
import { usePartiesStore } from '@/stores/parties'
const partiesStore = usePartiesStore();

// Components
// import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import AlphaFlow from '@/components/flows/AlphaFlow.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
import InvestorCard from '@/components/cards/InvestorCard.vue'
import InvestorSlideover from '@/components/slideovers/InvestorSlideover.vue'
import TableSticky from '@/components/applicationui/TableSticky.vue'
import Slideover from '@/components/applicationui/Slideover.vue'
import TableOrCard from '@/components/ui/TableOrCard.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue'
import SearchInput from '@/components/applicationui/SearchInput.vue'

// Libraries
import { UsersIcon } from '@heroicons/vue/24/outline'
import { UserPlusIcon } from '@heroicons/vue/20/solid'

// Globals
const loading = ref(true);

// Investor Alpha Flow
const showInvestorSlideover = ref(false);
const showFlow = ref(false);

// Table View
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false
const columns = ref([
  { key: 'first_name', key2: 'last_name', label: 'Name', type: 'two-strings', visible: true },
  { key: 'contact_email', label: 'Contact Email', type: 'bold', visible: true },
  { key: 'badge', label: 'Status', type: 'badge', visible: true },
  { key: 'phone', label: 'Phone', type: 'default-fill', visible: false },
  { key: 'parties_protected.kyc', label: 'KYC', type: 'badge', visible: true },
  { key: 'parties_protected.aml', label: 'AML', type: 'badge', visible: true },
  { key: 'accounts[0].accounts_protected.accredited_status', label: 'Accreditation', type: 'badge', visible: false },

  { key: 'employment_status', label: 'Employment Status', type: 'badge', visible: false },
  { key: 'employer_name', label: 'Employer Name', type: 'default-fill', visible: false },
  { key: 'occupation', label: 'Occupation', type: 'capitalize', visible: false },
  { key: 'employer_address', label: 'Employer Address', type: 'capitalize', visible: false },
  { key: 'employer_unit', label: 'Employer Unit #', type: 'default-fill', visible: false },
  { key: 'employer_city', label: 'Employer City', type: 'capitalize', visible: false },
  { key: 'employer_state', label: 'Employer State', type: 'capitalize', visible: false },
  { key: 'employer_zip', label: 'Employer Zip', type: 'default-fill', visible: false },
  { key: 'employer_country', label: 'Employer Country', type: 'default-fill', visible: false },

  { key: 'net_worth', label: 'Net Worth', type: 'money', visible: false },
  { key: 'average_annual_income', label: 'Average Annual Income', type: 'money', visible: false },
  { key: 'average_household_income', label: 'Average Household Income', type: 'money', visible: false },
  { key: 'current_annual_income', label: 'Current Annual Income', type: 'money', visible: false },
  { key: 'current_household_income', label: 'Current Household Income', type: 'money', visible: false },

  { key: 'address', label: 'Address', type: 'default-fill', visible: false },
  { key: 'zip', label: 'Zip', type: 'default-fill', visible: false },
  { key: 'city', label: 'City', type: 'capitalize', visible: false },
  { key: 'state', label: 'State', type: 'capitalize', visible: false },
  { key: 'country', label: 'Country', type: 'default-fill', visible: false },
  { key: 'created_at', label: 'Created', type: 'date', visible: true },
]);

// Search
const query = ref('');
const filteredInvestors = computed(() => {
  return query.value === '' ? investors.value :
    investors.value.filter((investor) => {
      if (investor.contact_email?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.first_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.last_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.tapi_party_id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.partners.title?.toLowerCase().includes(query.value.toLowerCase())) return true;
      // if (investor.partners?.partners_protected?.meta_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.phone?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.zip?.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});

// Investors
const investors = ref([])
const selectedInvestor = ref({})
const loading_first_investors = ref(true)
const loading_all_investors = ref(true)

async function setInvestors() {
  // get all the parties
  const parties = await partiesStore.getAllPartiesFullPaginated(0, 49);
  if (!parties) {
    loading_first_investors.value = false;
    loading_all_investors.value = false;
    return
  }

  // set the party badges based on the partner type
  parties.forEach((party) => { addBadgeToInvestor(party) })

  // set the investors to the returned and formatted parties
  investors.value = parties;
  loading_first_investors.value = false;

  // get the rest of the parties
  getRestOfInvestors()
}

async function getRestOfInvestors() {
  if (investors.value.length < 49) { loading_all_investors.value = false; return }
  const rest_of_parties = await partiesStore.getAllPartiesFullPaginated(50)

  // set the party badges based on the partner type
  rest_of_parties.forEach((party) => { addBadgeToInvestor(party) })

  investors.value = investors.value.concat(rest_of_parties)
  loading_all_investors.value = false
}

const addBadgeToInvestor = (investor) => {
  if (investor.partners.partners_protected.partner_type == 'dab') {
    investor.badge = investor?.accounts[0]?.accounts_protected?.accredited_status ?? 'Not Accredited'
  }
  else {
    if (investor?.accounts[0]?.positions?.length > 0) investor.badge = 'Invested'
    else if (investor?.accounts[0]?.transactions?.length > 0) investor.badge = 'Pending'
    else investor.badge = 'Not Invested'
  }
  return investor
}

// Click Handlers
const handleInvestorClicked = (investor) => {
  selectedInvestor.value = investor;
  showInvestorSlideover.value = true;
};

// Notifications
// const notification = ref(null);
// const notificationType = ref('success');
// const notificationText = ref('Success');
// const notificationTitle = ref('');

// Mounted
onMounted(async () => {
  if (userStore.user.profiles_protected.role == 'super_admin') {
    columns.value.splice(columns.value.length - 1, 0, { key: 'partners', label: 'Partner', type: 'partner-logo', visible: true });
    columns.value.splice(columns.value.length - 2, 0, { key: 'social', label: 'Social', type: 'default-fill', visible: false });
  }
  await setInvestors();
  loading.value = false;
});
</script>
