<template>
  <div class="flex items-center justify-between space-x-6 p-6">
    <div class="flex-1 truncate">
      <div class="flex items-center space-x-3">
        <h3 class="truncate text-sm font-medium text-gray-900 dark:text-white">{{ props.investor?.first_name + ' ' + props.investor?.last_name }}</h3>
        <span v-if="props.investor?.badge" :class="getBadgeClass(props.investor?.badge)">{{ capitalizeFirstLetter(props.investor.badge) }}</span>
      </div>
      <p class="mt-1 truncate text-sm text-gray-500 dark:text-neutral-400">{{ props.investor?.contact_email }}</p>
    </div>

    <div class="flex flex-col gap-2 flex-end items-end">
      <EllipsisVerticalIcon class="w-6 h-6 text-gray-500 hover:text-gray-600 dark:text-neutral-400 dark:hover:text-neutral-300" />
      <div v-if="props.role == 'super_admin'" class="mr-2">
        <PartnerLogo :partner="props.investor?.partners" size="logo-only" />
      </div>
    </div>
  </div>
</template>

<script setup>
// Components
import PartnerLogo from '@/components/widgets/PartnerLogo.vue'
// Libraries
import { getBadgeClass, capitalizeFirstLetter } from '@/helper/helper';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
// Props
const props = defineProps({
  investor: { //Expects a full party object with  badge value set
    type: Object,
    required: true,
    default: null
  },
  role: {
    type: String,
    required: false,
    default: 'investor'
  }
});
</script>