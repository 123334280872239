<template>
  <div class="rounded-lg cursor-pointer shadow bg-white dark:shadow-none dark:bg-neutral-800" @click="open = !open">
    <div class="flex items-center justify-between p-4">
      <div class="flex items-center gap-2">

        <ChartBarIcon v-if="title == 'Fund Longevity'" class="w-5 h-5 text-operacolor" />
        <UserGroupIcon v-else-if="title == 'Management'" class="w-5 h-5 text-operacolor" />
        <ClipboardDocumentIcon v-else-if="title == 'Offering Documents'" class="w-5 h-5 text-operacolor" />
        <FolderOpenIcon v-else-if="title == 'Assets Under Management'" class="w-5 h-5 text-operacolor" />
        <CurrencyDollarIcon v-else-if="title == 'Fees & Expenses'" class="w-5 h-5 text-operacolor" />
        <BanknotesIcon v-else-if="title == 'Financials'" class="w-5 h-5 text-operacolor" />
        <svg v-else-if="title == 'Liquidity'" xmlns="http://www.w3.org/2000/svg" height="1em" class="fill-operacolor" viewBox="0 0 384 512">
          <path
            d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z" />
        </svg>

        <span class="font-medium">{{ props.title }}</span>
      </div>
      <MinusIcon v-if="open" class="w-5 h-5 text-gray-500 dark:text-neutral-300" />
      <PlusIcon v-else class="w-5 h-5 text-gray-500 dark:text-neutral-300" />
    </div>
    <div v-if="open" class="px-4 pb-4 flex flex-col">
      <div class="flex gap-2">
        <div class="font-bold">Score: </div>
        <div v-if="props.score != 'Not yet available' || props.score != 'Not available'" class="flex items-center gap-1">
          <span class="mr-1 text-xs text-gray-400">({{ props.score }})</span>
          <OperaScore :score="props.score" />
        </div>
        <span v-else class="text-operacolor">{{ props.score }}</span>
      </div>
      <span v-if="props.explanation" class="mt-2 text-gray-600">{{ props.explanation }}</span>
      <router-link class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" :to="{ name: 'article', params: { id: '6f35924e-39f6-45fb-b5df-32d99ee58cc6' } }">See
        Methodology
        <ArrowTopRightOnSquareIcon class="w-3 h-4" />
      </router-link>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from 'vue'
// Libraries
import { PlusIcon, MinusIcon, ArrowTopRightOnSquareIcon, ChartBarIcon, UserGroupIcon, ClipboardDocumentIcon, FolderOpenIcon, CurrencyDollarIcon, BanknotesIcon } from '@heroicons/vue/24/outline'
// Components
import OperaScore from '@/components/ui/OperaScore.vue'
// Props
const props = defineProps({
  title: { type: String, required: true, default: '' },
  score: { type: String, required: false, default: 'Not available' },
  explanation: { type: String, required: false, default: '' }
})
// Globals
const open = ref(false)
</script>