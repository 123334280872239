<template>
  <div>

    <!-- Loading -->
    <div v-if="loading" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
      <div class="h-40 w-40 -mt-20">
        <SpinnerJump />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="p-5 sm:w-[500px] w-[90vw] dark:text-neutral-300">
      <div class="mb-5 dark:text-white">Please upload entity documents for your business/entity. If the information is to much to upload, please email {{ partnerStore.partner?.support_email ??
        'support@operaalts.com' }} with the need documents and information</div>

      <div class="flex items-center justify-between w-full cursor-pointer my-1 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
        @click="expandGeneralInstructions = !expandGeneralInstructions">
        <span class="font-semibold text_color_primary">
          General Instructions
        </span>
        <PlusIcon v-if="!expandGeneralInstructions" class="w-5 h-5 dark:text-neutral-300" />
        <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
      </div>

      <div v-if="expandGeneralInstructions" class="px-5">
        <ul class="list-disc text-sm ml-5">
          <li class="p-0.5">All documents and information below are required.</li>
          <li class="p-0.5">We perform full KYC/AML checks on all equity owners with a 25% or greater stake in the entity and all trustees, regardless if one is authorized to sign solely.</li>
          <li class="p-0.5">We do not take ID cards or driver's licenses for foreign investors. Passports are required for all foreign investors.</li>
          <li class="p-0.5">All documents must be provided in English.</li>
          <li class="p-0.5">Each entity's/trust documents may be written differently; additional information may be required at the discretion
            of the reviewing NCPS Principal.</li>
          <li class="p-0.5">Addresses must be the UBO's legal, residential address. P.O. boxes are not permitted.</li>
          <li class="p-0.5">Multi-layered entities: If an entity is owned or managed by another entity, you must collect that entity's
            documents as well. We must have appropriate documents for each layer, until we arrive at the UBOs.</li>
          <li class="p-0.5">Multi-layered trusts: If a trustee is another entity, you must collect that entity's documents as well. For each layer
            we must have the documents, until you drill down to natural persons.</li>
        </ul>
      </div>

      <div class="flex items-center justify-between w-full cursor-pointer my-1 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
        @click="expandLLCInstructions = !expandLLCInstructions">
        <span class="font-semibold text_color_primary">
          LLC's
        </span>
        <PlusIcon v-if="!expandLLCInstructions" class="w-5 h-5 dark:text-neutral-300" />
        <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
      </div>

      <div v-if="expandLLCInstructions" class="px-5">
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">1. Certificate of formation</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">2. Articles of organization</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">3. Operating agreement</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">4. Members' list and authorized signers' list (if not included in the documents):</div>
          <ul class="text-sm list-disc ml-4">
            <li class="p-0.5">For each member with a 25% or greater equity stake, as well as for the designated control person, we
              require their full legal name, DOB, SSN, and residential address.</li>
            <li class="p-0.5">For all U.S. individuals that fail KYC or AML, a driver's license or passport is required.</li>
            <li class="p-0.5">A passport is required for all foreign individuals.</li>
          </ul>
        </div>
      </div>

      <div class="flex items-center justify-between w-full cursor-pointer my-1 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
        @click="expandCorporationInstructions = !expandCorporationInstructions">
        <span class="font-semibold text_color_primary">
          Corporations
        </span>
        <PlusIcon v-if="!expandCorporationInstructions" class="w-5 h-5 dark:text-neutral-300" />
        <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
      </div>

      <div v-if="expandCorporationInstructions" class="px-5">
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">1. By-laws</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">2. Certificate of Formation</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">3. Articles of incorporation</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">4. Shareholders' list and authorized signers' list (if not included in the documents)</div>
          <ul class="text-sm list-disc ml-4">
            <li class="p-0.5">For each member with a 25% or greater equity stake, as well as for the designated control person, we
              require their full legal name, DOB, SSN and residential address.</li>
            <li class="p-0.5">For all U.S. individuals that fail KYC or AML, a driver's license or passport is required.</li>
            <li class="p-0.5">A passport is required for all foreign individuals.</li>
          </ul>
        </div>
      </div>

      <div class="flex items-center justify-between w-full cursor-pointer my-1 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
        @click="expandLimitedInstructions = !expandLimitedInstructions">
        <span class="font-semibold text_color_primary">
          Limited Partnerships/General Partnerships
        </span>
        <PlusIcon v-if="!expandLimitedInstructions" class="w-5 h-5 dark:text-neutral-300" />
        <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
      </div>

      <div v-if="expandLimitedInstructions" class="px-5">
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">1. Certificate of formation</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">2. Partnership agreement</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">3. LP list and general partner(s) list (if not included in the documents)</div>
          <ul class="text-sm list-disc ml-4">
            <li class="p-0.5">For the GP and for LPs with a 25% or greater equity stake, we require their full legal name, DOB, SSN
              and residential address.</li>
            <li class="p-0.5">For all U.S. individuals that fail KYC or AML a driver's license or passport is required.</li>
            <li class="p-0.5">A passport is required for all foreign individuals.</li>
          </ul>
        </div>
      </div>

      <div class="flex items-center justify-between w-full cursor-pointer my-1 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
        @click="expandTrustInstructions = !expandTrustInstructions">
        <span class="font-semibold text_color_primary">
          Trusts (Revocable and Irrevocable)
        </span>
        <PlusIcon v-if="!expandTrustInstructions" class="w-5 h-5 dark:text-neutral-300" />
        <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
      </div>

      <div v-if="expandTrustInstructions" class="px-5">
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">1. The full trust document or a certification of trust</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">2. For irrevocable trusts, their EIN.</div>
        </div>
        <div class="flex flex-col gap-1 mb-1 text-sm">
          <div class="secondary_subheader">3. List of all trustees, grantors (if still living), and protectors, including their full legal name,
            DOB, SSN, and residential address.</div>
          <ul class="text-sm list-disc ml-4">
            <li>For all U.S. individuals that fail KYC or AML, a driver's license or passport is required.</li>
            <li>A passport is required for all foreign individuals, as they will automatically fail KYC.</li>
          </ul>
        </div>
      </div>

      <div class="my-5 mt-5">
        <DragDrop @changedFiles="updateDataTransferObj" />
      </div>

      <div class="flex flex-row-reverse items-center justify-between">
        <button @click="handleNextClick()" class="self-end button_primary">{{ nextButtonText }}
          <ArrowRightIcon class="w-4 h-4" />
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, computed, watch } from 'vue'
// Stores
import { useUserStore } from '@/stores/user';
import { usePartiesStore } from '@/stores/parties';
import { usePartnerStore } from '@/stores/partner';
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const partiesStore = usePartiesStore();

// Libraries
import { ArrowRightIcon, PlusIcon, MinusIcon } from "@heroicons/vue/24/outline";

// Components
import DragDrop from "@/components/widgets/DragDrop.vue";
import SpinnerJump from "@/components/loading/SpinnerJump.vue";

// Globals
const expandGeneralInstructions = ref(true)
const expandLLCInstructions = ref(false)
const expandCorporationInstructions = ref(false)
const expandLimitedInstructions = ref(false)
const expandTrustInstructions = ref(false)

// Props
const props = defineProps({
  party: { type: Object, required: true, default: null },
})

// Emits
const emit = defineEmits(['next'])

// Handle Submit
const loading = ref(false)
async function handleNextClick() {
  // If we don't have any documents, skip
  if (noDocuments.value) { emit('next'); return }

  loading.value = true

  // Upload entity documents
  await uploadEntityDocs(props.party?.id, userStore.user.id, dt.value)

  emit('next')
}

// TODO make this function work for entity documents
async function uploadEntityDocs(party_id, user_id, dtArray) {
  const promises = [];

  for (let newFile of dtArray.files) {
    // Push upload to supabase promise to array
    const promise2 = partiesStore.uploadEntityDocumentToSupabase(user_id, party_id, newFile);
    promises.push(promise2);
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
}

// Documents
const noDocuments = ref(true)
const dt = ref(new DataTransfer())
const updateDataTransferObj = (dtObj) => {
  dt.value = dtObj;
  if (dt.value.files.length > 0) noDocuments.value = false;
  else noDocuments.value = true;
}

// Button Text
const nextButtonText = computed(() => {
  if (noDocuments.value) return "Do This Later"
  return "Next"
})
</script>