<template>
  <div>
    <!-- Loading -->
    <div v-if="loading" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
      <div class="h-28 w-28 -mt-8">
        <SpinnerFlip />
      </div>
    </div>
    <!-- Content -->
    <div v-else class="sm:w-[500px] w-[90vw] pt-2">
      <div class="flex items-start mt-4">
        <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 dark:bg-blue-950 rounded-full sm:mx-0 sm:h-10 sm:w-10">
          <ClipboardDocumentCheckIcon class="w-6 h-6 text-blue-500" aria-hidden="true" />
        </div>
        <div class="ml-4 text-left">
          <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white">Confirm Investment</DialogTitle>
          <p class="text-sm text-gray-500 dark:text-neutral-300">Please review and confirm the following investment information.</p>

          <div class="bg-white dark:bg-neutral-800 rounded-lg mb-4 mt-8 shadow">
            <!-- Offering Logo -->
            <div class="flex gap-3 items-center px-8 pb-4 pt-6 rounded-t-lg">
              <img :src="offeringLogo" alt="Offering Logo" class="size-8">
              <div class="secondary_header">{{ props?.offering?.name }}</div>
            </div>

            <div class="w-full relative flex justify-between items-center">
              <div class="size-8 z-10 bg-gray-50 dark:bg-neutral-950 rounded-full -ml-5 border-r-[1px] border-gray-200 dark:border-0"></div>
              <div class="flex-1 border-b-[1px] border-dashed border-gray-200 dark:border-neutral-600"></div>
              <div class="size-8 bg-gray-50 dark:bg-neutral-950 rounded-full -mr-5 border-l-[1px] border-gray-200 dark:border-0"></div>
            </div>
            <!-- Trade Details -->
            <div class="px-8 py-2 rounded-b-lg flex flex-col">
              <div v-if="props?.account" class="flex w-full justify-between flex-wrap pt-4 pb-4 items-center border-b-[1px] border-dashed border-gray-200 dark:border-neutral-600">
                <div class="text_color_tertiary text-sm">Account</div>
                <div class="text-gray-600 dark:text-neutral-300 truncate w-64">{{ props?.account?.name }}</div>
              </div>
              <div class="flex w-full flex-wrap justify-between items-center pb-1 pt-4">
                <div class="text_color_tertiary text-sm">Unit Price</div>
                <div class="text-gray-600 dark:text-neutral-300">{{ formatMoney(parseInt(props?.offering?.unit_price)) }}</div>
              </div>
              <div class="flex w-full flex-wrap justify-between items-center py-1">
                <div class="text_color_tertiary text-sm">Total Units</div>
                <div class="text-gray-600 dark:text-neutral-300">{{ props?.trade?.transactionUnits }}</div>
              </div>
              <div class="flex w-full flex-wrap justify-between items-center pt-1 pb-4 border-b-[1px] border-dashed border-gray-200 dark:border-neutral-600">
                <div class="text_color_tertiary text-sm">Unit Type</div>
                <div :class="getBadgeClass(props?.offering?.offering_type)">{{ capitalizeFirstLetter(props?.offering?.offering_type) }}</div>
              </div>
              <div class="flex w-full flex-wrap justify-between items-center py-4">
                <div class="text_color_tertiary">Total</div>
                <div class="text-gray-600 dark:text-neutral-300 font-semibold">{{ formatMoney(props?.trade?.investmentTotal) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-4 mt-5 flex flex-row-reverse">
        <button type="button" class="button_primary" @click="handleClick('confirmed')">
          Confirm
          <CheckCircleIcon class="w-4 h-4" />
        </button>
        <button type="button" class="button_secondary" @click="handleClick('canceled')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { onMounted, ref } from 'vue';
// Stores
import { useOfferingsStore } from '@/stores/offerings';
const offeringsStore = useOfferingsStore();
// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
// Libraries
import { formatMoney, capitalizeFirstLetter, getBadgeClass } from '@/helper/helper'
import { ClipboardDocumentCheckIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import { DialogTitle } from '@headlessui/vue'

// Props
const props = defineProps({
  offering: { type: Object, required: true, default: null },
  trade: { type: Object, required: true, default: null },
  account: { type: Object, required: false, default: null },
})

// Globals
const loading = ref(true)
const offeringLogo = ref('https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/receiptIcon.png?t=2024-06-18T17%3A11%3A57.627Z')

// Mounted
onMounted(async () => {
  offeringLogo.value = await offeringsStore.getOfferingFile(props.offering?.id, props.offering?.tapi_offering_id, 'logo')
  loading.value = false
})

// Emits
const emit = defineEmits(['confirmed', 'canceled'])

// Functions
const handleClick = (emitted) => { emit(emitted) }
</script>