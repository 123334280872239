<template>
  <div class="relative mt-6 flex-1">
    <!-- Your content -->
    <div>
      <div class="relative h-40 mb-8">
        <img class="absolute h-full w-full object-cover" :src="backgroundUrl" alt="" />
      </div>
      <div class="pb-6 px-4 sm:px-6">
        <div>

          <div class="flex items-end">
            <div class="flex-1">
              <div>
                <div class="flex items-center">
                  <h3 class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">{{ investor?.first_name + ' ' + investor?.last_name }}</h3>
                  <span class="ml-2.5" :class="getBadgeClass(investor?.badge)">{{ investor?.badge }}</span>
                </div>
                <div class="flex items-center gap-1.5">
                  <p class="text-sm text-gray-500 dark:text-neutral-400">{{ investor?.contact_email }}</p>
                  <Copy :text="investor?.contact_email" />
                </div>

              </div>
              <!-- Holdings -->
              <div class="mt-5 flex flex-wrap w-full gap-3">
                <div class="dark:text-white font-semibold">Transactions</div>
                <div v-if="loading" class="h-16 w-full rounded-lg skeleton"></div>
                <div v-else-if="investor?.accounts[0]?.transactions.length > 0" class="shadow-opera rounded-md w-full flex flex-col bg-white dark:bg-neutral-900" @click="toTransactions()">
                  <div v-for="transaction in investor?.accounts[0]?.transactions" :key="transaction.id"
                    class="flex justify-between items-center flex-wrap cursor-pointer p-4 hover:bg-slate-100 dark:hover:bg-neutral-800 rounded-md">
                    <div class="flex items-center">
                      <div class="mr-5">
                        <Tooltip :content="transaction.offerings.name">
                          <img :src="transaction.offerings.logo" alt="Offering Logo" class="h-8 w-8 object-contain">
                        </Tooltip>
                      </div>
                      <div class="badge_green">{{ `Total: ${formatMoney(transaction.price_per_unit * transaction.amount)}` }}</div>
                    </div>
                    <div class="w-44">
                      <div :class="transaction.status">{{ capitalizeFirstLetter(transaction.status) }}</div>
                    </div>
                    <div class="flex flex-col items-end">
                      <div class="text-xs text-gray-500 dark:text-neutral-400">{{ formatTime(transaction.created_at) }}</div>
                      <div class="text-xs text-gray-500 dark:text-neutral-400">{{ formatDateSimple(transaction.created_at) }}</div>
                    </div>
                  </div>
                </div>
                <div v-else class="shadow-opera rounded-md w-full flex justify-center bg-white dark:bg-neutral-900">
                  <div class="text-gray-500 dark:text-neutral-300 p-3">No Transactions</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-y-4">
        <dl class="space-y-4 px-4 sm:px-6">
          <div>
            <dt class="text-sm font-medium text-gray-900 dark:text-neutral-300 sm:w-40 sm:flex-shrink-0">Notes</dt>
            <!-- Notes -->
            <form @submit.prevent="updateNotes()" class="relative mt-2 bg-white dark:bg-neutral-800 rounded-md">
              <div class="overflow-hidden rounded-md shadow-sm ring- ring-inset ring-gray-300 dark:ring-neutral-600 focus-within:ring-2 focus-within:ring-operacolor">
                <label for="note" class="sr-only">Add notes</label>
                <textarea rows="3" name="note" id="note" v-model="notes"
                  class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 dark:text-neutral-100 placeholder:text-gray-400 dark:placeholder:text-neutral-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Add notes..." />

                <!-- Spacer element to match the height of the toolbar -->
                <div class="py-1" aria-hidden="true">
                  <!-- Matches height of button in toolbar (1px border + 36px content height) -->
                  <div class="py-px">
                    <div class="h-7" />
                  </div>
                </div>
              </div>

              <div class="absolute inset-x-0 bottom-0 flex justify-end py-1 pl-3 pr-2">
                <div class="flex-shrink-0">
                  <button type="submit" class="button_primary">
                    Save
                    <LoadGifButton v-if="updatingNotes" />
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div>
            <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-40 sm:flex-shrink-0 flex gap-1.5 items-center">
              Created Date
              <Copy :text="formatDate(investor?.created_at)" />
            </dt>
            <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">
              <p>{{ formatDate(investor?.created_at) }}</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-40 sm:flex-shrink-0 flex gap-1.5 items-center">
              Address
              <Copy :text="`${address} ${address2}`" />
            </dt>
            <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ `${address}` }}</dd>
            <dd class="text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ `${address2}` }}</dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-40 sm:flex-shrink-0 flex gap-1.5 items-center">
              Phone
              <Copy :text="investor?.phone" />
            </dt>
            <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ investor?.phone }}</dd>
          </div>
          <div v-if="userStore.user.profiles_protected.role == 'super_admin'">
            <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-52 sm:flex-shrink-0 flex gap-1.5 items-center">
              Social (Super Admin Only)
              <Copy :text="formatSocialSecurity(investor?.social)" />
            </dt>
            <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">
              <time datetime="1988-06-23">{{ formatSocialSecurity(investor?.social) }}</time>
            </dd>
          </div>
          <div class="flex gap-6 flex-wrap">
            <div>
              <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1 items-center cursor-pointer">
                <Tooltip content="Know your customer" class="flex gap-1 items-center">
                  KYC
                  <QuestionMarkCircleIcon class="size-4 text-gray-600 dark:text-neutral-400" />
                </Tooltip>
              </dt>
              <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">
                <span :class="getBadgeClass(investor?.parties_protected.kyc)">{{ capitalizeFirstLetter(investor?.parties_protected.kyc) }}</span>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1 items-center cursor-pointer">
                <Tooltip content="Anti-money laundering" class="flex gap-1 items-center">
                  AML
                  <QuestionMarkCircleIcon class="size-4 text-gray-600 dark:text-neutral-400" />
                </Tooltip>
              </dt>
              <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">
                <span :class="getBadgeClass(investor?.parties_protected.aml)">{{ capitalizeFirstLetter(investor?.parties_protected.aml) }}</span>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1 items-center">
                Accreditation
              </dt>
              <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">
                <span :class="getBadgeClass(investor?.accounts[0]?.accounts_protected?.accredited_status)">{{ investor?.accounts[0]?.accounts_protected?.accredited_status }}</span>
              </dd>
            </div>
          </div>
        </dl>
        <!-- Employer -->
        <dl class="space-y-4 px-4 sm:px-6">
          <div v-if="investor?.employment_status">
            <div class="p-4 rounded-lg bg-gray-100 dark:bg-neutral-900 space-y-3 shadow dark:shadow-none">
              <div v-if="investor?.employment_status">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-52 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Employment Status
                  <Copy :text="capitalizeFirstLetter(investor?.employment_status) ?? 'NA'" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ capitalizeFirstLetter(investor?.employment_status) ?? "NA" }}</dd>
              </div>
              <div v-if="investor?.occupation">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-52 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Occupation
                  <Copy :text="investor?.occupation" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ investor?.occupation }}</dd>
              </div>
              <div v-if="investor?.employer_name">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-52 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Employer Name
                  <Copy :text="investor?.employer_name" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ investor?.employer_name }}</dd>
              </div>
              <div v-if="employerAddress">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:w-52 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Employer Address
                  <Copy :text="`${employerAddress} ${employerAddress2}`" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ employerAddress }}</dd>
                <dd class="text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ employerAddress2 }}</dd>
              </div>
            </div>
          </div>
        </dl>
        <!-- Income -->
        <dl class="space-y-4 px-4 sm:px-6">
          <div v-if="investor?.networth != null">
            <div class="p-4 rounded-lg bg-gray-100 dark:bg-neutral-900 space-y-3 shadow dark:shadow-none">
              <div v-if="investor?.average_annual_income">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Personal Average Annual Income
                  <Copy :text="investor?.average_annual_income" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ formatMoney(investor?.average_annual_income) }}</dd>
              </div>
              <div v-if="investor?.average_household_income">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Household Average Annual Income
                  <Copy :text="investor?.average_household_income" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ formatMoney(investor?.average_household_income) }}</dd>
              </div>
              <div v-if="investor?.current_annual_income">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Current Personal Annual Income
                  <Copy :text="investor?.current_annual_income" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ formatMoney(investor?.current_annual_income) }}</dd>
              </div>
              <div v-if="investor?.current_household_income">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Current Average Annual Income
                  <Copy :text="investor?.current_household_income" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ formatMoney(investor?.current_household_income) }}</dd>
              </div>
              <div v-if="investor?.networth">
                <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100 sm:flex-shrink-0 flex gap-1.5 items-center">
                  Networth
                  <Copy :text="investor?.networth" />
                </dt>
                <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">{{ formatMoney(investor?.networth) }}</dd>
              </div>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>

</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { supabase } from '@/lib/supabaseClient';
import { useUserStore } from '@/stores/user';
import { usePartiesStore } from '@/stores/parties';
import { useOfferingsStore } from '@/stores/offerings';
// Components
import Copy from '@/components/applicationui/Copy.vue';
import LoadGifButton from '../loading/LoadGifButton.vue';
// Libraries
import { formatDate, formatTime, formatDateSimple, formatMoney, capitalizeFirstLetter, getBadgeClass, formatSocialSecurity } from '@/helper/helper';
// import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import Tooltip from '@/components/applicationui/Tooltip.vue';
// Props
const props = defineProps({
  partner: { type: Object, default: null },
})
// Model
const investor = defineModel()
// Emits
const emit = defineEmits(['closed', 'updated'])
// Stores
const userStore = useUserStore()
const partiesStore = usePartiesStore()
const offeringsStore = useOfferingsStore()
// Globals
const router = useRouter()
const updatingNotes = ref(false)
const address = ref('')
const address2 = ref('')
const employerAddress = ref()
const employerAddress2 = ref()
const notes = ref('')
const loading = ref(true)

// Mounted
onMounted(async () => {
  // Set Address Info
  setAddresses()
  // Set Notes
  setNotes()
  // Set Images
  await setOfferingLogos(investor.value?.accounts[0])
  // Ready
  loading.value = false
})

// Computed
const backgroundUrl = computed(() => {
  const { data } = supabase.storage.from('partners').getPublicUrl(`${props.partner.meta_name}/background`)
  return data.publicUrl
})

// Functions
async function updateNotes() {
  updatingNotes.value = true
  const succesfull = partiesStore.updateNotes(notes.value, investor.value.id)
  if (!succesfull) { console.error('Failed to update notes'); return }
  // add success message
  updatingNotes.value = false
  emit('updated')
  emit('closed')
}

async function setOfferingLogos(account) {
  if (!account) return
  const promises = []
  if (account?.positions?.length > 0) promises.push(setPositionOfferingLogo(account?.positions))
  if (account?.transactions?.length > 0) promises.push(setTransactionOfferingLogo(account?.transactions))
  await Promise.all(promises)
}

async function setPositionOfferingLogo(positions) {
  const promises = positions.map(async (position) => {
    position.offerings.logo = await offeringsStore.getOfferingFile(position.offerings.id, position.offerings.tapi_offering_id, 'logo')
  })
  await Promise.all(promises)
}

async function setTransactionOfferingLogo(transactions) {
  const promises = transactions.map(async (transaction) => {
    transaction.offerings.logo = await offeringsStore.getOfferingFile(transaction.offerings.id, transaction.offerings.tapi_offering_id, 'logo')
  })
  await Promise.all(promises)
}

// Simple Functions
const setNotes = () => {
  notes.value = investor.value.parties_protected.notes
}

const setAddresses = () => {
  // Foramt investor address
  address.value = `${investor.value.address}`
  address2.value = `${investor.value.city}, ${investor.value.state}, ${investor.value.country} ${investor.value.zip}`
  if (investor.value.unit) address.value = `${investor.value.address}, ${investor.value.unit}`
  // Format employer address
  if (investor.value.employment_status == 'employed' && investor.value.employer_address) {
    employerAddress.value = `${investor.value.employer_address}`
    employerAddress2.value = `${investor.value.employer_city}, ${investor.value.employer_state}, ${investor.value.employer_country} ${investor.value.employer_zip}`
    if (investor.value.employer_unit) employerAddress.value = `${investor.value.employer_address} ${investor.value.employer_unit}`
  }
}

const toTransactions = () => {
  router.push('admin-transactions')
  emit('closed')
}
</script>