<template>
  <fieldset>

    <div class="relative flex items-start">
      <div class="flex h-6 items-center">
        <input v-model="checkbox" :id="props?.label" :aria-describedby="props.label" :name="props.label" type="checkbox"
          class="h-4 w-4 rounded border-gray-300 text-operacolor dark:checked:bg-operacolor focus:ring-operacolor cursor-pointer dark:bg-neutral-700 dark:border-none" />
      </div>
      <div class="ml-3 text-sm leading-6">
        <label v-if="props.label" :for="props.label" class="font-medium text-gray-900 dark:text-neutral-100 cursor-pointer" v-html="props.label"></label>
        <p v-if="props.description" :id="`${props.label}-description`" class="text-gray-500" v-html="props.description"></p>
      </div>
    </div>

  </fieldset>
</template>

<script setup>
const props = defineProps({
  label: { type: String, required: false },
  description: { type: String, required: false },
});

const checkbox = defineModel(false);
</script>
