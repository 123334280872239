<template>
  <button :type="props.type" :disabled="(props.disabled || props.loading)" :class="[
    props.disabled ? 'disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 dark:disabled:bg-neutral-600 dark:disabled:text-neutral-400' :
      (props.loading ? 'bg-opacity-70' : `bg-opacity-100 ${getColorHoverClass(props.color)}`),
    `${getButtonClass(props.size)} h-fit inline-flex items-center justify-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${getColorClass(props.color)}`
  ]">
    <div v-if="props.loading" class="absolute">
      <LoadGifButton class="h-5 w-5" aria-hidden="true" />
    </div>
    <div :class="[props.loading ? 'opacity-0' : '', 'flex items-center gap-2']">
      <component v-if="props.iconFirst && props.icon" :is="props.icon" :class="getIconSize(props.size)" aria-hidden="true" />
      {{ props.text }}
      <component v-if="!props.iconFirst && props.icon" :is="props.icon" :class="getIconSize(props.size)" aria-hidden="true" />
    </div>
  </button>
</template>


<script setup>
import LoadGifButton from '@/components/loading/LoadGifButton.vue';
// Props
const props = defineProps({
  size: { type: String, required: false, default: 'md' },
  text: { type: String, required: true, default: 'Button' },
  icon: { required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
  iconFirst: { type: Boolean, required: false, default: false },
  loading: { type: Boolean, required: false, default: false },
  type: { type: String, required: false, default: 'button' },
  color: { type: String, required: false, default: 'operacolor' }
})
// Functions
const getButtonClass = (size) => {
  if (size === 'xs') return 'rounded px-2 py-1 text-xs gap-x-2'
  if (size === 'sm') return 'rounded px-2 py-1 text-sm gap-x-2'
  if (size === 'md') return 'rounded-md px-2.5 py-1.5 text-sm gap-x-1.5'
  if (size === 'lg') return 'rounded-md px-3 py-2 text-sm gap-x-1.5'
  if (size === 'xl') return 'rounded-md px-3.5 py-2.5 text-sm gap-x-2'
}
const getIconSize = (size) => {
  if (size === 'xs') return 'size-3'
  if (size === 'sm') return 'size-4'
  if (size === 'md') return 'size-5'
  if (size === 'lg') return 'size-5'
  if (size === 'xl') return 'size-5'
}
const getColorHoverClass = (color) => {
  if (color === 'operacolor') return 'hover:bg-operacolorlight'
  if (color === 'red') return 'hover:bg-red-500'
  if (color === 'yellow') return 'hover:bg-yellow-400 dark:hover:bg-yellow-500'
}
const getColorClass = (color) => {
  if (color === 'operacolor') return 'bg-operacolor focus-visible:outline-operacolor'
  if (color === 'red') return 'bg-red-600 focus-visible:outline-red-600'
  if (color === 'yellow') return 'bg-yellow-500 focus-visible:outline-yellow-500 dark:bg-yellow-600 focus-visible:outline-yellow-600'
}
</script>