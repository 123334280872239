<template>
  <div class="px-4 dark:text-neutral-300 sm:px-6 lg:px-8">

    <!-- Popups -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- Popups -->

    <!-- Slideovers -->
    <ArticlesSlideover v-model="showOperaArticles" :articles="unused_articles" @article-added="handleOperaArticleAdded" />
    <!-- Slideovers -->

    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Edit Content -->
    <div v-else-if="showArticleEdit" class="flex flex-col">
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <div class="button_secondary" @click="showArticleEdit = false">
            <ArrowLeftIcon class="w-4 h-4" />
            Back
          </div>
          <!-- Tab Header -->
          <h3 v-if="is_create" class="primary_header">Create Article</h3>
          <h3 v-else class="primary_header">Edit Article</h3>

          <!-- Save Buttons -->
          <div>
            <div v-if="is_create" class="button_primary" @click="createArticle">Save & Publish</div>
            <div v-else class="flex gap-5">
              <div class="button_red" @click="deleteArticle">Delete</div>
              <div class="button_primary" @click="updateArticle">Save Changes</div>
            </div>

          </div>


        </div>
      </div>

      <!-- Content -->
      <div>
        <!-- Article Edit/Create -->
        <div v-if="showArticleEdit">
          <ArticleEdit v-model="article" :is_new="is_create" />
        </div>
      </div>
    </div>

    <!-- Articles Content -->
    <div v-else class="flex flex-col">
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Your Articles</h3>

          <div class="flex items-center gap-4 flex-wrap">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                  </div>
                  <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                </div>
              </div>
            </div>
            <!-- Add North Capital Article -->
            <button class="button_secondary" @click="showOperaArticles = true">
              <AcademicCapIcon class="size-4" />
              Opera Curated Articles
            </button>
            <!-- Add Article -->
            <button class="button_primary" @click="showArticleEditor(true)">
              New Article
              <PlusIcon class="size-4" />
            </button>
          </div>

        </div>
      </div>

      <!-- Articles -->
      <div class="flex gap-10 flex-wrap">
        <!-- Article Edit/Create -->
        <div v-for="a in filteredArticles" :key="a.id">
          <ArticleCard :article="a" :edit="true" @edit="showArticleEditor(false, a)" />
        </div>
      </div>

      <!-- Opera Articles -->
      <div v-if="opera_articles && opera_articles.length > 0" class="mt-8">
        <div>
          <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
            <!-- Tab Header -->
            <h3 class="primary_header">Opera Curated Articles</h3>
          </div>
        </div>

        <div class="flex gap-10 flex-wrap">
          <!-- Article Edit/Create -->
          <div v-for="a in opera_articles" :key="a.id" class="relative group">
            <ArticleCard :article="a" :preview="true" />
            <div @click="handleRemove(a.id)"
              class="absolute top-2 right-2 bg-red-600 hover:bg-red-500 shadow text-white rounded-md px-2 py-1 group-hover:visible invisible flex gap-1 items-center cursor-pointer">
              Remove
              <TrashIcon class="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue';
import { supabase } from '@/lib/supabaseClient';
import { PlusIcon, MagnifyingGlassIcon, ArrowLeftIcon, AcademicCapIcon, TrashIcon } from '@heroicons/vue/24/outline';

// Components
import NotificationPopup from '@/components/popups/NotificationPopup.vue';
import ArticleEdit from '@/components/widgets/ArticleEdit.vue';
import ArticleCard from '@/components/cards/ArticleCard.vue';
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue';
import ArticlesSlideover from '@/components/slideovers/ArticlesSlideover.vue';

// Stores
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();

// Setup
const loading = ref(false)

// Create/Edit Article
const showArticleEdit = ref(false);
const showOperaArticles = ref(false);
const is_create = ref(true);
const defaultArticleValues = {
  partner_id: partnerStore.partner.id,
  type: 'custom',
  content: []
}
const article = ref({ ...defaultArticleValues });
const showArticleEditor = (is_creating, article_passed) => {
  is_create.value = is_creating;
  if (is_creating) article.value = { ...defaultArticleValues }
  else article.value = article_passed
  showArticleEdit.value = true;
}
const createArticle = async () => {
  const articleToSave = { ...article.value };
  const preview_file = articleToSave.previewFile;
  delete articleToSave.image;
  delete articleToSave.previewFile;
  const { data, error } = await supabase.from('articles').insert(articleToSave).select()
  if (error) {
    console.log(error)
    showNotification('failure', 'Sorry', 'We were unable to create this article. Please try again. If the issue persists, please contact tech support');
    return
  }
  const article_id = data[0].id

  showArticleEdit.value = false;
  const success = await uploadPreviewImage(preview_file, article_id)
  if (!success) return

  const preview_url = `${process.env.VUE_APP_SUPABASE_URL}/storage/v1/object/public/articles/${article_id}/${preview_file.name}`
  const success1 = await updateArticlePreviewUrl(preview_url, article_id)
  if (!success1) return

  const uploadPromises = articleToSave.content
    .filter(content => content.type === 'image')
    .map(content => uploadContentImage(content.file, article_id));

  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    showNotification('failure', 'Sorry', 'We were unable to upload your article image. Please try again. If the issue persists, please contact tech support');
    return;
  }
  article.value = { ...defaultArticleValues };

  showNotification('success', 'Success', 'Article created successfully');

  getArticles()
}

const deleteArticle = async () => {
  const { error } = await supabase.from('articles').delete().eq('id', article.value.id)
  if (error) {
    console.log(error)
    showNotification('failure', 'Sorry', 'We were unable to delete this article. Please try again. If the issue persists, please contact tech support');
    return
  }
  article.value = { ...defaultArticleValues };
  showArticleEdit.value = false;
  showNotification('success', 'Success', 'Article deleted successfully');
  getArticles()
}

const updateArticle = async () => {
  const articleToSave = { ...article.value };
  const preview_file = articleToSave.previewFile;
  delete articleToSave.image;
  delete articleToSave.previewFile;
  const { error } = await supabase.from('articles').update(articleToSave).eq('id', articleToSave.id)
  if (error) {
    console.log(error)
    showNotification('failure', 'Sorry', 'We were unable to create this article. Please try again. If the issue persists, please contact tech support');
    return
  }
  const article_id = articleToSave.id

  showArticleEdit.value = false;
  if (preview_file) {
    const success = await uploadPreviewImage(preview_file, article_id)
    if (!success) return

    const preview_url = `${process.env.VUE_APP_SUPABASE_URL}/storage/v1/object/public/articles/${article_id}/${preview_file.name}`
    const success1 = await updateArticlePreviewUrl(preview_url, article_id)
    if (!success1) return
  }

  const uploadPromises = articleToSave.content
    .filter(content => content.type === 'image')
    .map(content => uploadContentImage(content.file, article_id));

  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    showNotification('failure', 'Sorry', 'We were unable to upload your article image. Please try again. If the issue persists, please contact tech support');
    return;
  }

  article.value = { ...defaultArticleValues }

  showNotification('success', 'Success', 'Article updated successfully');

  getArticles()
}

const uploadPreviewImage = async (file, article_id) => {
  const { error } = await supabase.storage.from('articles').upload(`${article_id}/${file.name}`, file, {
    cacheControl: '3600',
    upsert: true,
  });
  if (error) {
    console.log("error uploading image: ", error);
    showNotification('failure', 'Sorry', 'We were unable to upload your article image. Please try again. If the issue persists, please contact tech support');
    return
  }
  return true
}

const uploadContentImage = async (file, article_id) => {
  const { error } = await supabase.storage.from('articles').upload(`${article_id}/${file.name}`, file, {
    cacheControl: '3600',
    upsert: true,
  });
  if (error) {
    console.log("error uploading image: ", error);
    showNotification('failure', 'Sorry', 'We were unable to upload an article image. Please try again. If the issue persists, please contact tech support');
    return
  }
  return true
}

const updateArticlePreviewUrl = async (preview_url, article_id) => {
  const { error } = await supabase.from('articles').update({ preview_url }).eq('id', article_id);
  if (error) {
    console.log("error uploading image: ", error);
    showNotification('failure', 'Sorry', 'We were unable to upload your article image. Please try again. If the issue persists, please contact tech support');
    return
  }
  return true
}

const handleOperaArticleAdded = () => {
  showOperaArticles.value = false
  getOperaArticles()
  getUnusedArticles()
}

// Remove Article from Partner
const handleRemove = async (id) => {
  // Get the partners articles
  let opera_articles = partnerStore.partner.opera_articles
  if (!opera_articles) opera_articles = []
  if (!opera_articles.includes(id)) return
  opera_articles = opera_articles.filter(article => article !== id)

  await partnerStore.updateArticles(opera_articles)

  getOperaArticles()
  getUnusedArticles()
}

// Search
const query = ref('');
const filteredArticles = computed(() => {
  if (!articles.value) return []
  return query.value === '' ? articles.value :
    articles.value.filter((article) => {
      if (article?.title?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (article?.description?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (article?.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (article?.author?.toLowerCase().includes(query.value.toLowerCase())) return true;
    })
});

// Get Articles
const articles = ref([]);
const getArticles = async () => {
  const { data, error } = await supabase.from('articles').select('*').eq('partner_id', partnerStore.partner.id).order('created_at', { ascending: false })
  if (error) {
    console.log(error)
    showNotification('failure', 'Sorry', 'We were unable to get your articles. If the issue persits, please contact tech support.');
    return
  }
  articles.value = data
}

// Get Partner Articles
const opera_articles = ref([]);
const getOperaArticles = async () => {
  const { data, error } = await supabase.from('opera_articles').select('*').in('id', partnerStore.partner.opera_articles).order('created_at', { ascending: false })
  if (error) {
    console.log(error)
    showNotification('failure', 'Sorry', 'We were unable to get your articles. If the issue persits, please contact tech support.');
    return
  }
  opera_articles.value = data
  console.log(opera_articles.value)
}

// Get Articles
const unused_articles = ref([]);
const getUnusedArticles = async () => {
  const { data, error } = await supabase.from('opera_articles').select('*').order('created_at', { ascending: false })
  if (error) {
    console.log(error)
    showNotification('failure', 'Sorry', 'We were unable to get your articles. If the issue persits, please contact tech support.');
    return
  }
  unused_articles.value = data.filter(article => !partnerStore.partner.opera_articles.includes(article.id))
}

// Mounted
onMounted(async () => {
  await Promise.all([getArticles(), getOperaArticles(), getUnusedArticles()]);
});

// Notifications
const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationText = ref('');
const showNotification = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
</script>