<template>
  <div class="flex flex-col">
    <div @drop.prevent="drop" @dragenter.prevent @dragover.prevent="hoverClass = 'bg-blue-100 bg-opacity-70 dark:bg-blue-900 dark:bg-opacity-70'" @dragleave.prevent="hoverClass = ''"
      class="@apply h-[150px] p-4 grow flex items-center justify-center gap-2.5 rounded-2xl border-2 border-dashed border-border dark:border-neutral-500" :class="hoverClass">
      <!-- <img src="../assets/icons/inbox_in.svg" class="upload_icon"> -->
      <div class="dark:text-neutral-300">Drag & Drop Files or <b class="cursor-pointer text-operacolor hover:text-operacolorlight" @click="simulateClick()">Browse Files</b></div>
      <input ref="chooseFile" type="file" id="upload" class="hidden" @change="fileAddedViaBrowse" multiple />
    </div>
    <!-- Uploaded Files Display -->
    <div class="flex flex-wrap gap-y-5 mt-5 h-fit">

      <!-- Documents list -->
      <DocumentsList :documents="dtView" @remove="removeFile" />

      <!-- </div> -->
    </div>
  </div>
</template>


<script setup>
// Essentials
import { ref } from 'vue';
// Components
import DocumentsList from '@/components/ui/DocumentsList.vue';
// Globals
const hoverClass = ref('');
const dt = ref(new DataTransfer())
const dtView = ref([]);
const chooseFile = ref(null);
// Emits
const emit = defineEmits(['changedFiles'])

// Simple Functions
const drop = (e) => {
  hoverClass.value = '';
  // Add files
  addFiles(e.dataTransfer.files);
};

const fileAddedViaBrowse = (event) => {
  // Add files
  addFiles(event.target.files);
};

const addFiles = (files) => {
  for (let file of files) {
    if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'application/pdf') { alert('Accepted file types are jpg, png, & pdf.'); }
    else if (file.size > 100000000) { alert('Maximum file size is 100MB.'); }
    else {
      dt.value.items.add(file);
      var url = '';
      var important = '';
      if (file.type != 'application/pdf') { url = URL.createObjectURL(file); important = ' !important'; }
      dtView.value.push({ name: file.name, url: url, important: important, size: file.size });
    }
    emit('changedFiles', dt.value);
  }
};

const removeFile = (fileName) => {
  var dtTemp = new DataTransfer();
  for (let file of dt.value.files) {
    if (file.name != fileName) {
      dtTemp.items.add(file);
    }
  }
  dt.value = dtTemp;
  dtView.value = [];
  for (let file of dt.value.files) {
    var url = '';
    var important = '';
    if (file.type != 'application/pdf') { url = URL.createObjectURL(file); important = ' !important'; }
    dtView.value.push({ name: file.name, url: url, important: important, size: file.size });
  }
  emit('changedFiles', dt.value);
};

const simulateClick = () => { chooseFile.value.click(); };


</script>