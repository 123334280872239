<template>
  <div>
    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <div v-else-if="article_id == '6f35924e-39f6-45fb-b5df-32d99ee58cc6'">
      <RatingMethodology />
    </div>

    <!-- Article -->
    <div v-else>
      <ArticlePage :article="article" />
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { supabase } from '@/lib/supabaseClient';
import { useRoute } from 'vue-router';

import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import ArticlePage from '@/components/widgets/ArticlePage.vue';
import RatingMethodology from '@/articles/RatingMethodology.vue';

const route = useRoute();

const loading = ref(true);
const article_id = ref(route.params.id);
const article = ref({});

onMounted(async () => {
  await getArticle(article_id.value);
  loading.value = false;
});

async function getArticle(id) {
  const { data, error } = await supabase.from('articles').select('*').eq('id', id).limit(1)
  if (error) {
    // TODO: Show error notification
    console.log(error)
    return
  }
  if (data.length <= 0) {
    const { data: curated_data, error: curated_error } = await supabase.from('opera_articles').select('*').eq('id', id).limit(1)
    if (curated_error) {
      // TODO: Show error notification
      console.log(curated_error)
      return
    }
    article.value = curated_data[0]
    return
  }
  article.value = data[0]
}
</script>