<template>
  <div>
    <div class="flex items-start w-fit">
      <div class="flex items-center justify-center flex-shrink-0 mx-auto dark:bg-yellow-950 bg-yellow-100 rounded-full h-10 w-10">
        <ExclamationTriangleIcon class="w-6 h-6 text-yellow-600" aria-hidden="true" />
      </div>
      <div class="flex-shrink mt-2 mr-8 ml-4 text-left">
        <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white">Change Role</DialogTitle>
        <div class="flex flex-col gap-5 my-2">
          <div class="rounded-lg bg-yellow-100 dark:bg-yellow-950 p-4 mt-3">
            <div class="flex">
              <div>
                <h3 class="text-sm font-medium text-yellow-800 dark:text-yellow-300">Warning</h3>
                <div class="mt-2 text-sm text-yellow-700 dark: dark:text-yellow-500">
                  <p>Please note that changing a user's role will greatly influence thier experience and permissions on the site. Don't make any changes to a user's role unless you are
                    certain!</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col mb-2">
            <!-- Role -->
            <div class="select_menu_label">Role</div>
            <SelectMenu v-model="selectedRole" :items="roles" />
          </div>

          <div class="flex flex-col gap-1 mb-3">
            <p class="text-sm text-gray-600 dark:text-neutral-400">Name: {{ props.user.parties?.first_name || '-' }} {{ props.user.parties?.last_name ?? '' }}</p>
            <p class="text-sm text-gray-600 dark:text-neutral-400">Email: {{ props.user?.email ?? '-' }}</p>
          </div>

        </div>

      </div>
    </div>
    <div class="flex gap-4 w-full justify-end items-center mt-5">
      <ButtonSecondary @click="emit('close')" text="Cancel" />
      <ButtonPrimary color="yellow" text="Save Changes" @click="updateRole()" />
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue';

// Stores
import { useProfilesStore } from '@/stores/profiles'
const profilesStore = useProfilesStore();
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue';
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

// Libraries
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import { DialogTitle } from '@headlessui/vue'

// Props
const props = defineProps({
  user: { type: Object, required: true, default: null },
})

// Emits
const emit = defineEmits(['role-updated', 'close'])

// Roles
const roles = [
  { id: 1, label: 'Investor', value: 'investor' },
  // { id: 3, label: 'Partner Admin', value: 'partner_admin' }
];
const selectedRole = ref(roles[0]);
async function updateRole() {
  await profilesStore.updateRole(props.user.id, selectedRole.value.value);
  emit('role-updated');
};

// Mounted
onMounted(() => {
  if (partnerStore.partner.allow_advisors) roles.push({ id: 2, label: 'Advisor', value: 'advisor' })
  if (userStore.user?.profiles_protected?.role == 'super_admin') roles.push({ id: 3, label: 'Partner Admin', value: 'partner_admin' })
  else if (userStore.user?.profiles_protected?.role == 'partner_admin') roles.push({ id: 3, label: 'Admin', value: 'partner_admin' })
  // Set Current Role
  if (props?.user?.profiles_protected?.role == 'super_admin') selectedRole.value = { id: 4, label: 'Super Admin', value: 'super_admin' }
  else if (props?.user?.profiles_protected?.role == 'partner_admin') {
    if (userStore.user?.profiles_protected?.role == 'super_admin') selectedRole.value = { id: 3, label: 'Partner Admin', value: 'partner_admin' }
    else selectedRole.value = { id: 3, label: 'Admin', value: 'partner_admin' }
  }
  else if (props?.user?.profiles_protected?.role == 'advisor') selectedRole.value = { id: 2, label: 'Advisor', value: 'advisor' }
  else if (props?.user?.profiles_protected?.role == 'investor') selectedRole.value = { id: 1, label: 'Investor', value: 'investor' }
});
</script>