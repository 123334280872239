<template>
  <div class="relative mt-8 flex-1 flex flex-col justify-between px-4 sm:px-6">
    <div class="flex flex-col gap-10">
      <CreateUserGroup :user_groups="offering_partner?.user_groups" :partner_id="offering_partner.id" />
      <div>
        <fieldset>
          <legend class="text-base font-semibold leading-6 text-gray-900 dark:text-white flex gap-1 items-center">
            Allowed Users Groups
            <InfoCircle info="Selected user groups will have access to this offering" />
          </legend>
          <div class="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 dark:border-neutral-700 dark:divide-neutral-700 max-h-96 overflow-scroll">
            <div v-for="(user_group, index) in props.offering_partner?.user_groups" :key="index" class="relative flex items-start py-4">
              <Checkbox :label="user_group" v-model=checkedUserGroups[index] class="pl-1" />
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="flex justify-end gap-5">
      <ButtonSecondary text="Cancel" size="lg" @click="handleClose" />
      <ButtonPrimary text="Save" size="lg" @click="handleSave" :loading="loadingSave" />
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import CreateUserGroup from '@/components/ui/CreateUserGroup.vue';
import Checkbox from '@/components/applicationui/Checkbox.vue';
import InfoCircle from '@/components/ui/InfoCircle.vue';
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

const props = defineProps({
  offering_id: { type: String, required: false },
  offering_partner: { type: Object, required: true },
  allowed_user_groups: { type: Array, required: false }
})

const emit = defineEmits(['close', 'allowedUserGroups'])

import { useOfferingsStore } from '@/stores/offerings';
const offeringsStore = useOfferingsStore()

const checkedUserGroups = ref(Array(props.offering_partner.user_groups?.length).fill(false))

const loadingSave = ref(false)

const handleClose = () => { emit('close') }

const handleSave = async () => {
  if (props.offering_id) {
    loadingSave.value = true
    await updateOfferingAllowedGroups()
    loadingSave.value = false
    emit('close')
    return
  }

  const allowedUserGroups = props.offering_partner.user_groups?.filter((_, index) => checkedUserGroups.value[index]);
  emit('allowedUserGroups', allowedUserGroups)
  emit('close')
}

const updateOfferingAllowedGroups = async () => {
  const allowedUserGroups = props.offering_partner.user_groups?.filter((_, index) => checkedUserGroups.value[index]);
  await offeringsStore.updateOfferingAllowedUserGroups(props.offering_id, allowedUserGroups)
}

onMounted(() => {
  if (props.allowed_user_groups) {
    props.allowed_user_groups.forEach(group => {
      const index = props.offering_partner.user_groups?.indexOf(group);
      if (index !== -1) {
        checkedUserGroups.value[index] = true;
      }
    });
  }
})
</script>
