<template>
  <div class="relative mt-8 flex flex-col flex-1">

    <!-- Update Role -->
    <Popup v-model="open">
      <UpdateRole :user="props.user" @role-updated="emit('save'); open = false" @close="open = false" />
    </Popup>

    <div class="relative h-40 mb-8">
      <img class="absolute h-full w-full object-cover" :src="backgroundUrl" alt="" />
    </div>

    <!-- Content -->
    <div class="px-4 sm:px-6 flex flex-col justify-between flex-1">

      <div class="flex flex-col gap-10">

        <div class="flex flex-col gap-1">
          <div class="flex items-center">
            <h3 v-if="props?.user?.parties?.first_name || props?.user?.parties?.last_name" class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white mr-2.5">
              {{ `${props?.user?.parties?.first_name ?? ''} stuff ${props?.user?.parties?.last_name ?? ''}` }}</h3>
            <span class="capitalize" :class="getBadgeClass(props?.user?.profiles_protected?.role)">
              {{ getUserFriendlyString(props?.user?.profiles_protected?.role, userStore?.user?.profiles_protected?.role) }}
            </span>
          </div>
          <p v-if="props?.user?.email" class="text-gray-500 dark:text-neutral-400 flex items-center gap-2">{{ props?.user?.email }}
            <Copy :text="props?.user?.email" />
          </p>
          <p v-if="props?.user?.parties?.phone" class="text-gray-500 dark:text-neutral-400 flex items-center gap-2">{{ props?.user?.parties?.phone }}
            <Copy :text="props?.user?.parties?.phone" />
          </p>

          <div class="mt-3">
            <dt class="text-sm font-medium text-gray-900 dark:text-neutral-100">Created Date</dt>
            <dd class="mt-1 text-sm text-gray-500 dark:text-neutral-400 sm:col-span-2">
              <p>{{ formatDate(props?.user?.created_at) }}</p>
            </dd>
          </div>

          <div class="mt-3">
            <div class="text-sm font-medium text-gray-900 dark:text-neutral-100">User Groups</div>
            <div class="mt-2 flex gap-2 h-6">
              <div v-if="selected_groups.length <= 0" class="text-sm ml-1 text-gray-500">No Groups Assigned</div>
              <div v-for="group in selected_groups" class="badge_gray">
                {{ group }}
                <XMarkIcon class="w-4 h-4 ml-1 cursor-pointer hover:text-red-600" @click="removeUserFromGroup(group)" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="all_groups?.length > 0" class="flex items-end gap-5">
          <div class="bg-white dark:bg-neutral-900 p-4 rounded-lg shadow flex flex-col gap-5 w-full">
            <fieldset>
              <legend class="text-base font-semibold leading-6 text-gray-900 dark:text-white flex gap-1 items-center">
                User Groups
              </legend>
              <div class="mt-4 divide-y max-h-60 divide-gray-200 border-t border-gray-200 dark:border-neutral-700 dark:divide-neutral-700 overflow-y-scroll">
                <div v-for="(user_group, index) in all_groups" class="relative flex items-start py-4 min-w-52">
                  <Checkbox :label="user_group" v-model=checkedUserGroups[index] class="pl-1" />
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <CreateUserGroup :partner_id="props?.user?.profiles_protected?.partner_id" :user_groups="all_groups" />
      </div>

      <div class="flex justify-between gap-5 mt-10">
        <ButtonPrimary text="Update User's Role" size="lg" color="yellow" @click="open = true" class="w-fit" />
        <div class="flex gap-5">
          <ButtonSecondary text="Cancel" size="lg" @click="emit('close')" />
          <ButtonPrimary text="Save Changes" size="lg" @click="handleSave" :loading="loadingSave" />
        </div>

      </div>

    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { supabase } from '@/lib/supabaseClient';

import { getBadgeClass, formatDate, getUserFriendlyString } from '@/helper/helper';
import { XMarkIcon } from '@heroicons/vue/24/outline';

import UpdateRole from '@/components/popups/UpdateRole.vue'
import CreateUserGroup from '@/components/ui/CreateUserGroup.vue';
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import Checkbox from '@/components/applicationui/Checkbox.vue';
import Copy from '@/components/applicationui/Copy.vue';
import Popup from '@/components/applicationui/Popup.vue';

import { useUserStore } from '@/stores/user';
const userStore = useUserStore()
import { useProfilesStore } from '@/stores/profiles';
const profilesStore = useProfilesStore()

const emit = defineEmits(['close', 'save'])

const props = defineProps({
  user: { type: Object, required: true }
})

// Save
const loadingSave = ref(false)
const handleSave = async () => {
  if (loadingSave.value) return
  loadingSave.value = true
  await profilesStore.updateUsersGroup(props.user.id, selected_groups.value)
  emit('save')
  emit('close')
}

// Roles
const open = ref(false);

// Computed
const backgroundUrl = computed(() => {
  const { data } = supabase.storage.from('partners').getPublicUrl(`${props.user?.profiles_protected?.partners?.meta_name}/background`)
  return data.publicUrl
})

// Groups
const selected_groups = ref([])
const all_groups = ref([])
const checkedUserGroups = ref([])

watch(checkedUserGroups, (newVal) => {
  selected_groups.value = all_groups.value.filter((_, index) => newVal[index]);
}, { deep: true });

// Edit Groups
const removeUserFromGroup = async (group) => {
  // Remove group from selected groups
  selected_groups.value = selected_groups.value.filter(g => g !== group)

  // Remove group from checked user groups
  const index = all_groups.value.indexOf(group);
  if (index !== -1) {
    checkedUserGroups.value[index] = false;
  }
}

// Mounted
onMounted(() => {
  // Set selected groups
  if (props?.user?.profiles_protected?.groups) selected_groups.value = props?.user?.profiles_protected?.groups

  // Set all groups
  if (props?.user?.profiles_protected?.partners?.user_groups) all_groups.value = props?.user?.profiles_protected?.partners?.user_groups

  // Create checked user groups array
  checkedUserGroups.value = Array(all_groups.value.length).fill(false)

  // Set checked user groups correctly
  selected_groups.value.forEach(group => {
    const index = all_groups.value?.indexOf(group);
    if (index !== -1) {
      checkedUserGroups.value[index] = true;
    }
  });
})
</script>