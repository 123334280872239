<template>
  <!-- Table Component -->
  <div class="flow-root">
    <div>
      <div v-if="props.allow_export" class="mb-2 text-right">
        <div @click.stop="exportToCSV" class="button_secondary">
          Download
          <ArrowDownTrayIcon class="w-4 h-4" />
        </div>
      </div>
      <div class="inline-block w-full align-middle">
        <div class="overflow-x-scroll max-h-[70vh] rounded-lg shadow dark:shadow-none scrollbar-hide ring-1 ring-black ring-opacity-5">
          <table class="min-w-full divide-y divide-gray-300 dark:divide-neutral-700">
            <thead class="bg-gray-100 dark:bg-neutral-800">
              <tr>
                <th v-for="column in props.columns" :key="column.key" scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300 first:pl-4 first:sm:pl-6 first:px-0 first:pr-3 last:pr-5 last:flex last:justify-end whitespace-nowrap">
                  <span v-if="column.label" class="flex items-center gap-1">
                    {{ column.label }}
                    <ArrowsUpDownIcon @click="sortByColumn(column)" class="w-4 h-4 mt-0.5 hover:text-operacolor cursor-pointer"
                      :class="[sorter.key == column.key ? 'text-operacolor' : 'text-gray-400 dark:text-neutral-500']" />
                  </span>

                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
              <tr v-for="row in sortedRows" :key="row" @click="handleClick(row)" :class="[props.isClickable ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-neutral-950' : '']">
                <td v-for="column in props.columns" :key="column.key"
                  class="first:pl-4 first:sm:pl-6 first:font-medium first:dark:text-neutral-100 first:text-gray-900 py-4 px-3 text-sm text-gray-500 dark:text-neutral-300 truncate max-w-52 whitespace-nowrap last:pr-5 last:text-right">
                  <span v-if="column.type == 'badge'" :class="helper.getBadgeClass(getNestedValue(row, column.key))">{{ helper.capitalizeFirstLetter(getNestedValue(row, column.key)) ?? '-' }}</span>
                  <span v-else-if="column.type == 'badge-alt'" :class="helper.getBadgeClassAlt(getNestedValue(row, column.key))">{{ helper.capitalizeFirstLetterAlt(getNestedValue(row, column.key)) ??
                    '-' }}</span>
                  <span v-else-if="column.type == 'badge-gray'" :class="getNestedValue(row, column.key) ? 'badge_gray' : ''">{{ (getNestedValue(row, column.key)) ??
                    '-' }}</span>
                  <span v-else-if="column.type == 'date'">{{ helper.formatDate(getNestedValue(row, column.key)) ?? '-' }}</span>
                  <span v-else-if="column.type == 'double'">{{ `${getNestedValue(row, column.key) ?? '-'} ${getNestedValue(row, column.key2) ?? ''}` }}</span>
                  <span v-else-if="column.type == 'bold'" class="font-semibold text-gray-900 dark:text-neutral-100">{{ getNestedValue(row, column.key) ?? '-' }}</span>
                  <span v-else-if="column.type == 'small'" class="text-xs">{{ getNestedValue(row, column.key) ?? '-' }}</span>
                  <span v-else-if="column.type == 'capitalize'">{{ helper.capitalizeFirstLetter(getNestedValue(row, column.key)) ?? '-' }}</span>
                  <span v-else-if="column.type == 'money'">{{ helper.formatMoney(getNestedValue(row, column.key)) ?? '-' }}</span>
                  <span v-else-if="column.type == 'total'">{{ helper.formatMoney(getNestedValue(row, column.key) * getNestedValue(row, column.key2))
                    ?? '-' }}</span>
                  <span v-else-if="column.type == 'partner-logo'">
                    <PartnerLogo :partner="getNestedValue(row, column.key)" size="small" />
                  </span>
                  <span v-else-if="column.type == 'truncate'">{{ helper.truncateText(getNestedValue(row, column.key)) ?? '-' }}</span>
                  <span v-else-if="column.type == 'button-primary'">
                    <div @click.stop="handleButtonPrimaryClicked(row)" class="button_primary">{{ column.key }}</div>
                  </span>
                  <span v-else-if="column.type == 'button-secondary'">
                    <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary">{{ column.key }}</div>
                  </span>
                  <span v-else-if="column.type == 'custom-esign'" class="flex justify-end">
                    <div v-if="getNestedValue(row, column.key) == 'SIGNED'" class="flex w-fit flex-shrink-0 gap-1 items-center">
                      <CheckCircleIcon class="w-6 h-6 text-green-600" />
                      <span class="secondary_header ml-1">Signing Complete</span>
                    </div>
                    <div v-else>
                      <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary mr-5">
                        Resend Email
                        <LoadGifButton v-if="row.loading" class="-ml-px -mr-px" />
                        <EnvelopeIcon v-else class="w-4 h-4" />
                      </div>
                      <div @click.stop="handleButtonPrimaryClicked(row)" class="button_primary">
                        Sign
                        <PencilIcon class="w-4 h-4" />
                      </div>
                    </div>
                  </span>
                  <span v-else-if="column.type == 'custom-signed'">
                    <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary">{{ column.key }}</div>
                  </span>
                  <span v-else>{{ getNestedValue(row, column.key) ?? '-' }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="items-center justify-center w-full bg-white dark:bg-neutral-950" v-if="props.rows <= 0">
            <div class="flex items-center justify-center w-full py-4 font-semibold dark:bg-neutral-900 dark:text-neutral-300">{{ props.empty_message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed } from 'vue';
import * as helper from '@/helper/helper';
// Components
import PartnerLogo from '@/components/widgets/PartnerLogo.vue'
import LoadGifButton from '@/components/loading/LoadGifButton.vue'
// Libraries
import { ArrowsUpDownIcon, ArrowDownTrayIcon } from '@heroicons/vue/20/solid';
import { PencilIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
// Stores
// Props
const props = defineProps({
  //This is an array of objects that contains the data to be displayed in the table
  rows: {
    type: Array,
    required: true,
    default: null
  },
  // This is an array of objects that contains the columns there should be two values to each object, key and label
  columns: {
    type: Array,
    required: true,
    default: null
  },
  // This is a boolean that determines if the row is clickable
  isClickable: {
    type: Boolean,
    required: false,
    default: false
  },
  allow_export: {
    type: Boolean,
    required: false,
    default: true
  },
  // This is a string that determines the title of the exported csv file
  export_title: {
    type: String,
    required: false,
    default: 'opera'
  },
  empty_message: {
    type: String,
    required: false,
    default: 'No results found. Try adjusting your filters?'
  }
});
// Emits
const emit = defineEmits(['click-primary', 'click-secondary']);
// Globals
const sorter = ref({ key: null, order: 'asc' });
// Mounted
// Computed
const sortedRows = computed(() => {
  if (!sorter.value.key) return props.rows;

  const sortedArray = [...props.rows].sort((a, b) => {
    const valueA = getNestedValue(a, sorter.value.key);
    const valueB = getNestedValue(b, sorter.value.key);

    if (valueA == null) return sorter.value.order === 'asc' ? -1 : 1;
    if (valueB == null) return sorter.value.order === 'asc' ? 1 : -1;
    if (valueA < valueB) return sorter.value.order === 'asc' ? -1 : 1;
    if (valueA > valueB) return sorter.value.order === 'asc' ? 1 : -1;
    return 0;
  });

  return sortedArray;
});
// Functions
function sortByColumn(column) {
  if (sorter.value.key === column.key) {
    sorter.value.order = sorter.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    sorter.value.key = column.key;
    sorter.value.order = 'asc';
  }
}

function getNestedValue(obj, path) { // Utility function to get nested values
  if(path === '') return obj;
  return path.split('.').reduce((acc, part) => {
    // Handle array indices in the path
    const match = part.match(/^(.+)\[(\d+)\]$/);
    if (match) {
      const arrayKey = match[1];
      const index = parseInt(match[2], 10);
      return acc && acc[arrayKey] && acc[arrayKey][index];
    }
    return acc && acc[part];
  }, obj);
}

function exportToCSV() {
  const rows = sortedRows.value;
  const columns = props.columns;

  let csvContent = columns.map(col => col.label).join(",") + "\n";

  rows.forEach(row => {
    let rowContent = columns.map(col => {
      let value = getNestedValue(row, col.key);
      if (value === null || value === undefined) {
        value = '-';
      }
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""'); // Escape double quotes
      }
      return `"${value}"`;
    }).join(",");
    csvContent += rowContent + "\n";
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${props.export_title}_table_data.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const handleClick = (row) => {
  if (props.isClickable) emit('click-primary', row)
}

const handleButtonPrimaryClicked = (row) => {
  emit('click-primary', row)
}

const handleButtonSecondaryClicked = (row) => {
  emit('click-secondary', row)
}
</script>