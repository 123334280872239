<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-neutral-500 dark:bg-opacity-25 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-neutral-950 py-6 shadow-xl">

                  <!-- Header -->
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                        <h3 class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">Forum Manager</h3>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300 focus:outline-none" @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Header -->

                  <!-- Content -->
                  <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">

                    <!-- Header -->
                    <div>
                      <div class="mt-5 border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
                        <!-- Tab Header -->
                        <h3 class="primary_header">Comments</h3>
                      </div>
                    </div>

                    <!-- Opera Articles -->
                    <div class="flex gap-10 flex-wrap">
                      <ForumFeed :offering_id="props.offering_id" />
                    </div>

                  </div>
                  <!-- Content -->

                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Components
import ForumFeed from '@/components/widgets/ForumFeed.vue';

// Libraries
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

// Model
const open = defineModel()

// Props
const props = defineProps({
  offering_id: { type: String, required: false, default: null }
})
</script>
