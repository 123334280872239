<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />

    <PlaidForm v-if="showPlaid" :show="showPlaid" :tapi_account_id="accountsStore?.holdingAccount.tapi_account_id" :account_id="accountsStore?.holdingAccount.id" :update="hasAchAccount"
      @closed="showPlaid = false;" @submitted="successfullyAddedAch()"
      @existing="notify('failure', 'Existing Bank Account', 'Looks like you already have a bank account. At the moment only one bank account can be connected at a time. Please delete you bank external account before adding a new one')"
      @failure="notify('failure', 'Sorry', 'We were unable to connect your bank account, if the issues persists please contact tech support')" />

    <StripePopup v-if="showStripe" v-model="showStripe" :tapi_account_id="accountsStore?.holdingAccount.tapi_account_id" :account_id="accountsStore?.holdingAccount.id" :update="hasCreditCard"
      @closed="showStripe = false;" @submitted="successfullyAddedCreditCard()"
      @failure="notify('failure', 'Sorry', 'We were unable to connect your credit card, if the issues persists please contact tech support')" />

    <DeletePlaid v-model="showDeleteBank" @confirmed="handlePlaidDelete" />
    <!-- End Popups -->

    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Needs Profile Party -->
    <div v-else-if="!userStore.user?.party_id">
      <div class="text-center">
        <CreditCardIcon class="w-12 h-12 mx-auto text-gray-400" />
        <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Payment Methods</h3>
        <p class="mt-1 text-sm text-gray-500">Get started by adding a payment method<br />You must fill out your profile information first.</p>
        <div class="mt-6">
          <button type="button" @click="navigateToSettings()" class="button_primary">
            Add Profile
            <ArrowLongRightIcon class="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>

    <!-- Ready -->
    <div v-else>
      <!-- Empty State, Bank Account -->
      <div v-if="plaidAccount?.statusCode != '101' && !creditCard">
        <div class="text-center">
          <CreditCardIcon class="w-12 h-12 mx-auto text-gray-400" />
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Payment Methods</h3>
          <p class="mt-1 text-sm text-gray-500">Get started by adding a payment method<br />You must fill out your profile information first.</p>
          <div class="mt-6">
            <button v-if="userStore.user?.party_id" type="button" @click="showPlaid = true" class="button_primary">
              Add Bank Account
              <PlusIcon class="w-4 h-4" aria-hidden="true" />
            </button>
            <button v-else type="button" @click="navigateToSettings()" class="button_primary">
              Add Profile
              <ArrowLongRightIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div v-else>

        <!-- Header -->
        <div>
          <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
            <!-- Tab Header -->
            <h3 class="primary_header">Payment Methods</h3>
          </div>
        </div>

        <div class="flex flex-wrap gap-10">
          <!-- Bank Account Info -->
          <div v-if="plaidAccount?.statusCode == '101'" class="w-fit">

            <div class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-300 mb-2">Bank Account information</div>
            <div class="relative flex flex-col gap-5 shadow-opera sm:px-6 lg:gap-x-8 lg:px-8 lg:py-8 background dark:shadow-none dark:bg-neutral-900 bg-white">
              <TrashIcon class="absolute top-3 right-3 w-5 h-5 text-red-600 hover:text-red-500 cursor-pointer" @click="showDeleteBank = true" />
              <div class="font-medium text-gray-900 dark:text-white">{{ plaidAccount.statusDesc.AccountNickName }}</div>

              <div class="flex gap-5 text-gray-900 dark:text-white">
                <div class="p-1 bg-white ring-1 ring-gray-200 rounded-md">
                  <div class="h-5 w-5 bg-no-repeat bg-[center_center] bg-contain default" :class="bankLogo"></div>
                </div>

                <div>**** **** **** {{ plaidAccount.statusDesc.AccountNumber }}</div>
                <div class="badge_purple">{{ plaidAccount.statusDesc.accountType }}</div>
              </div>


              <div class="flex justify-between items-center gap-5 mt-2">
                <div class="flex items-center gap-2 bg-white dark:bg-neutral-800 pl-2 pr-3 py-1.5 rounded-md shadow bg-gradient-to-r from-purple-600 to-purple-700 text-white">
                  <LockClosedIcon class="w-5 h-5 mb-0.5" />
                  <span class="text-sm">Linked securely via</span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="-18.89985 -11.86825 163.7987 71.2095" class="-m-2">
                    <path
                      d="M66.248 16.268c-1.057-.889-2.861-1.333-5.413-1.333h-5.756v17.788h4.304v-5.575h1.928c2.34 0 4.056-.515 5.148-1.546 1.23-1.155 1.849-2.693 1.849-4.613 0-1.991-.687-3.565-2.06-4.721m-5.044 6.855h-1.821V18.96h1.636c1.99 0 2.985.698 2.985 2.094 0 1.378-.934 2.068-2.8 2.068m14.469-8.188h-4.488v17.788h9.69v-4.026h-5.202zm13.995 0l-7.05 17.788h4.832l.924-2.586H94.5l.845 2.586h4.886l-7-17.788zm-.053 11.601l1.849-6.08 1.82 6.08h-3.67zm12.858 6.187h4.489V14.934h-4.489zm21.917-14.454a7.376 7.376 0 00-2.14-2.053c-1.355-.854-3.204-1.28-5.545-1.28h-5.914v17.787h6.918c2.5 0 4.506-.817 6.02-2.453 1.514-1.635 2.27-3.805 2.27-6.508 0-2.15-.537-3.981-1.61-5.493m-7.182 10.427h-1.927v-9.734h1.954c1.373 0 2.428.43 3.168 1.287.74.857 1.11 2.073 1.11 3.647 0 3.2-1.435 4.8-4.305 4.8M18.637 0L4.09 3.81.081 18.439l5.014 5.148L0 28.65l3.773 14.693 14.484 4.047 5.096-5.064 5.014 5.147 14.547-3.81 4.008-14.63-5.013-5.146 5.095-5.063L43.231 4.13 28.745.083l-5.094 5.063zM9.71 6.624l7.663-2.008 3.351 3.44-4.887 4.856zm16.822 1.478l3.405-3.383 7.63 2.132-6.227 6.187zm-21.86 9.136l2.111-7.705 6.125 6.288-4.886 4.856-3.35-3.44zm29.547-1.243l6.227-6.189 1.986 7.74-3.404 3.384zm-15.502-.127l4.887-4.856 4.807 4.936-4.886 4.856zm-7.814 7.765l4.886-4.856 4.81 4.936-4.888 4.856zm15.503.127l4.886-4.856L36.1 23.84l-4.887 4.856zM4.57 29.927l3.406-3.385 4.807 4.937-6.225 6.186zm14.021 1.598l4.887-4.856 4.808 4.936-4.886 4.856zm15.502.128l4.887-4.856 3.351 3.439-2.11 7.705zm-24.656 8.97l6.226-6.189 4.81 4.936-3.406 3.385-7.63-2.133zm16.843-1.206l4.886-4.856 6.126 6.289-7.662 2.007z"
                      fill-rule="evenodd" fill="#FFF" />
                  </svg>
                </div>
                <div class="button_secondary" @click="showPlaid = true">Change
                  <PencilSquareIcon class="w-4 h-4" />
                </div>
              </div>

            </div>
          </div>

          <!-- Credit Card Info -->
          <div v-if="creditCard">
            <div class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-300 mb-2">Credit Card</div>
            <section aria-labelledby="summary-heading" class="w-fit">

              <div class="bg-white dark:bg-neutral-900 shadow-opera px-4 py-6 rounded-md sm:rounded-lg sm:px-6 lg:gap-x-8 lg:px-8 lg:py-8">
                <dl class="flex gap-8 text-sm md:gap-x-16">
                  <div>
                    <dt class="font-medium text-gray-900 dark:text-white">Credit Card information</dt>
                    <dd class="-ml-4 -mt-1 flex flex-wrap">
                      <div class="ml-4 mt-4 flex-shrink-0">
                        <CreditCardLogo :cardType="creditCard?.cardType" />
                      </div>
                      <div class="ml-4 mt-4">
                        <p class="text-gray-900 dark:text-white">Ending with {{ creditCard.creditCardNumber }}</p>
                        <p class="text-gray-600 dark:text-neutral-400 mt-1">Card added {{ formatDate(creditCard.createdDate) }}</p>
                      </div>
                    </dd>
                  </div>
                </dl>

                <div class="mt-6 flex justify-between gap-5 items-center">
                  <div class="flex items-center gap-2 bg-gradient-to-r from-[#635bff] to-[#a960ee] pl-2 pr-3 py-1.5 rounded-md shadow">
                    <LockClosedIcon class="w-5 h-5 text-white" /> <span class="text-white text-sm">Linked securely via</span>
                    <img :src="stripeUrl" class="h-5" />
                  </div>
                  <div @click="showStripe = true" class="button_secondary h-fit">Change
                    <PencilSquareIcon class="w-4 h-4" />
                  </div>
                </div>

              </div>

            </section>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useAccountsStore } from '@/stores/accounts'
// Components
import StripePopup from '@/components/popups/StripePopup.vue'
import PlaidForm from '@/components/popups/PlaidForm.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
import CreditCardLogo from '@/components/ui/CreditCardLogo.vue'
import DeletePlaid from '@/components/popups/DeletePlaid.vue'
// Libraries
import { getBank, formatDate } from '@/helper/helper'
import { LockClosedIcon, CreditCardIcon } from '@heroicons/vue/20/solid'
import { PencilSquareIcon, PlusIcon, ArrowLongRightIcon, TrashIcon } from '@heroicons/vue/24/outline'
// Stores
const userStore = useUserStore();
const accountsStore = useAccountsStore();
// Globals
const router = useRouter();
const plaidAccount = ref({});
const creditCard = ref()
const loading = ref(true);
const bankLogo = ref('default');
const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('Success');
const notificationText = ref('Your account has been successfully connected');
const showPlaid = ref(false);
const showStripe = ref(false);
const showDeleteBank = ref(false);
const hasAchAccount = ref(false);
const hasCreditCard = ref(false);
const plaidUrl = ref('https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/plaidLogo.png');
const stripeUrl = ref('https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/stripe.png');

// Mounted
onMounted(async () => {
  // Check that profile has an associated party
  if (!userStore?.user?.party_id) { loading.value = false; return; }

  // get linked account and credit card concurrently
  await Promise.all([
    setLinkedAccount(accountsStore.holdingAccount?.id, accountsStore.holdingAccount?.tapi_account_id),
    setLinkedCreditCard(accountsStore.holdingAccount?.id, accountsStore.holdingAccount?.tapi_account_id)
  ]);

  loading.value = false;
});

// Functions
async function setLinkedAccount(account_id, tapi_account_id) {
  const data = await accountsStore.getAchAccount(account_id, tapi_account_id)
  if (!data || data.statusCode == 149 || data.statusCode == '149') return
  // if we returned successfully then we have an account
  hasAchAccount.value = true;
  // Set bank logo
  bankLogo.value = getBank(data.statusDesc.AccountNickName.toLowerCase());
  if (bankLogo.value == 'default') bankLogo.value = getBank(data.statusDesc.bankName.toLowerCase());
  plaidAccount.value = data;
}

async function setLinkedCreditCard(account_id, tapi_account_id) {
  const data = await accountsStore.getCreditCard(account_id, tapi_account_id)
  if (!data || data?.statusCode != 101 || data?.statusCode != '101') return
  creditCard.value = data.creditcardDetails;
  hasCreditCard.value = true;
}

async function successfullyAddedCreditCard() {
  notify('success', 'Successfully Connected', 'Your bank account has been connected succesfully');
  setLinkedAccount(accountsStore.holdingAccount?.id, accountsStore.holdingAccount?.tapi_account_id)
}

async function successfullyAddedAch() {
  notify('success', 'Successfully Connected', 'Your bank account has been connected succesfully');
  setLinkedAccount(accountsStore.holdingAccount.id, accountsStore.holdingAccount.tapi_account_id)
}

async function handlePlaidDelete() {
  const data = await accountsStore.deleteExternalAccount(accountsStore.holdingAccount.id, accountsStore.holdingAccount.tapi_account_id)
  if (data?.statusCode == 101 || data?.statusCode == '101') {
    notify('success', 'Successfully Deleted', 'Your bank account has been deleted succesfully');
    window.location.reload();
  } else {
    notify('failure', 'Failed to Delete', 'We were unable to delete your bank account, please try again later');
  }
}

// Simple Functions
const navigateToSettings = () => { router.push('/dashboard/settings'); }

const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
}
</script>

<style scoped>
.default {
  background-image: url('@/assets/icons/bank.svg');
}

.chase {
  background-image: url('@/assets/icons/chase.png');
}

.wellsfargo {
  background-image: url('@/assets/icons/wellsfargo.svg');
}

.boa {
  background-image: url('@/assets/icons/boa.png');
}

.citi {
  background-image: url('@/assets/icons/citi.png');
}

.gs {
  background-image: url('@/assets/icons/gs.png');
}

.truist {
  background-image: url('@/assets/icons/truist.png');
}

.schwab {
  background-image: url('@/assets/icons/schwab.png');
}

.fidelity {
  background-image: url('@/assets/icons/fidelity.png');
}

.cf {
  background-image: url('@/assets/icons/cf.png');
}

.dark .invert-img {
  filter: invert(1);
}
</style>
